import {useEffect, useState} from "react";
import {templates} from "../../data/templates.js";
import {useEdits} from "../../contexts/edits.js";
import {isEmptyObj} from "../../libs/tools";
import {useSetting} from "../../contexts/setting";
import {PLACEHOLDERS} from "../../data/constants";
import cn from "classnames";
import TemplateControls from "../../layout/TemplateControls";

const Social = () => {
  const {fontOption, visible} = useSetting();
  const {editTitles, titles, subtitles, editSubtitles} = useEdits();
  const [defaultTitles, setDefaultTitles] = useState(PLACEHOLDERS[fontOption].title);
  const [defaultSubtitles, setDefaultSubtitles] = useState(PLACEHOLDERS[fontOption].subtitle);

  useEffect(() => {
    if (isEmptyObj(titles)) {
      editTitles(defaultTitles, "main", false);
    }
    if (isEmptyObj(subtitles)) {
      editSubtitles(defaultSubtitles, "main", false);
    }
  }, []);

  return templates.landingpage.map((item) => (
      <div
          key={item.id}
          className={cn("template-wrap", {hidden: !visible.some((x) => x === item.id)})}
      >
        <div className="template-annotation">
          <span className="label">{`${item.label} (${item.dimensions})`}</span>
          <TemplateControls id={item.id} />
        </div>
        {item.getComponent()}
      </div>
  ));
};

export default Social;
