import {useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";

import {useEdits} from "contexts/edits";
import {useImages} from "contexts/images";
import {useSetting} from "contexts/setting";
import {isEmptyObj} from "libs/tools";
import {API} from "api";

export function useScene() {
  const [isSceneLoaded, setIsSceneLoaded] = useState(false);
  const {id: templateId} = useParams();

  const {
    loadTitles,
    loadSubtitles,
    editFontVariation,
    loadPositions,
    loadNames,
    loadBadges,
    loadDates,
  } = useEdits();
  const {editCustomBg, loadImages, editTransform, rerenderBg, editUploadTransform} = useImages();
  const {
    editProduct,
    editType,
    editChannel,
    editBgOption,
    editLayoutOption,
    editIconOption,
    editHash,
    loadLocked,
    loadVisible,
  } = useSetting();

  const {isLoading, isPending, isSuccess, isFetching, isError, error, status, fetchStatus, data} =
    useQuery({
      queryKey: ["fetch-scene", templateId],
      queryFn: () => API.fetchScene(templateId),
      enabled: Boolean(templateId),
      refetchOnWindowFocus: false,
      retry: false,
    });

  useEffect(() => {
    if (templateId && isSuccess && !isFetching) {
      const {
        product,
        fontVariation,
        channel,
        type,
        names,
        dates,
        titles,
        subtitles,
        positions,
        bgScale,
        bgX,
        bgY,
        bgOption,
        customBg,
        layoutOption,
        iconOption,
        images,
        uploadY,
        uploadX,
        uploadScale,
        locked,
        visible,
        badges,
      } = data;

      editHash(templateId);
      if (product) editProduct(product);
      if (channel) editChannel(channel);
      if (type) editType(type);
      if (bgOption) editBgOption(bgOption);
      if (layoutOption) editLayoutOption(+layoutOption);
      if (images) loadImages(images);
      if (iconOption) editIconOption(+iconOption);
      if (customBg) editCustomBg(customBg);
      if (titles) loadTitles(titles);
      if (subtitles) loadSubtitles(subtitles);
      if (names) loadNames(names);
      if (positions) loadPositions(positions);
      if (badges) loadBadges(badges);
      if (dates) loadDates(dates);
      if (locked) loadLocked(locked);
      if (visible) loadVisible(visible);
      if (fontVariation) editFontVariation(fontVariation);
      if (bgY || bgX || bgScale) {
        editTransform(bgX, bgY, bgScale);
        rerenderBg();
      }

      if (uploadY || uploadX || uploadScale) {
        editUploadTransform(uploadX, uploadY, uploadScale);
        rerenderBg();
      }
      setIsSceneLoaded(true);
    }
  }, [
    templateId,
    isSuccess,
    data,
    editProduct,
    editChannel,
    editType,
    editBgOption,
    editLayoutOption,
    loadImages,
    editIconOption,
    editCustomBg,
    loadTitles,
    loadSubtitles,
    loadNames,
    loadPositions,
    loadBadges,
    loadDates,
    loadLocked,
    loadVisible,
    editFontVariation,
  ]);

  const isLoaded = useMemo(() => {
    return templateId && isSuccess && !isFetching && !isEmptyObj(data) && isSceneLoaded;
  }, [templateId, isSuccess, isFetching, isSceneLoaded]);

  return {
    isLoaded,
    isError,
    error,
    status,
    fetchStatus,
    isFetching,
    isLoading,
    isPending,
    isSuccess,
    data,
  };
}
