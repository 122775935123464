import React, {useState} from "react";
import "./index.css";
import Textarea from "../../../layout/Textarea/Textarea";
import {logoNorm, logoIDE, logoAcademyBlack, UI} from "../../../data/constants";
import { checkIfIDE, getLogoUrl } from "../../../libs/tools";
import BgTransformer from "../../../layout/BgTransformer";
import {useSetting} from "../../../contexts/setting";
import {useEdits} from "../../../contexts/edits";
import {useImages} from "../../../contexts/images";
import cn from "classnames";
import Icon from "../../../layout/Icon";
import ImageUpload from "../../../layout/ImageUpload";

const YouTubeCommunityLivestream = ({id, zoom}) => {
  const {product, layoutOption, iconOption} = useSetting();
  const {bgUrl} = useImages();
  const [isInline, setIsInline] = useState(true);
  const { titles, names, dates, positions, badges } = useEdits();
  const logoURL = getLogoUrl(product.value, bgUrl);
  const title = titles[id] || titles.main;
  const badge = badges[id] || badges.main;
  const name = names[id] || names.main;
  const date = dates[id] || dates.main;
  const position = positions[id] || positions.main;
  const iconUrl = `${UI}/Icons/${iconOption}.svg`;
  const templateClass = cn("template", `layout-${layoutOption}`);
  const logoClass = cn("logo", {ide: checkIfIDE(product.value)});

  return (
    <>
      <div id={id} className={templateClass} style={{"--zoom-level": zoom}}>
        <div>
          <BgTransformer src={bgUrl} id={id} />

          <div className="content">
            <header className="template-header">
              <div className={logoClass}>
                <img src={logoURL} alt="background" />
              </div>
              {(["JB", "EDU", "MP", "GDV",  "DN"].includes(product.value)) ? "" : (
                <div className="product-name">
                  {product.label}
                </div>
              )}
              <div className="badge">
                <Textarea id={id} text={badge} inline={isInline}/>
              </div>
            </header>

            <div className="title-wrap">
              <h1 className="title">
                <Textarea id={id} text={title} />
              </h1>
              <div className="date">
                <Textarea id={id} text={date} />
              </div>
              <div className="name">
                <Textarea id={id} text={name} />
              </div>
              <div className="position">
                <Textarea id={id} text={position} />
              </div>
            </div>
            <div className="upload-wrap">
              <ImageUpload id={id} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default YouTubeCommunityLivestream;
