import React, {useEffect} from "react";
import {useEdits} from "../../contexts/edits";
import {useSetting} from "../../contexts/setting";
import cn from "classnames";
import "./index.css";
import {UI} from "../../data/constants";

const Button = ({value, handleClick, src, isCurrent}) => {
  const btnClass = cn("layout-button", {selected: isCurrent});
  return (
    <button
      value={value}
      className={btnClass}
      onClick={() => handleClick(value)}
      style={{backgroundImage: `url(${src})`}}
    ></button>
  );
};

const LayoutSelector = ({amount}) => {
  const {layoutOption, editLayoutOption, channel} = useSetting();
  const getSrc = (value, channel) => `${UI}/Layouts/${channel.value}/${value}.svg`;
  const onBgButtonClick = (value) => {
    editLayoutOption(value);
  };

  return (
    <div className="layout-selector">
      {[...new Array(amount)].map((x, i) => (
        <Button
          key={i + 1}
          value={i + 1}
          src={getSrc(i + 1, channel)}
          handleClick={onBgButtonClick}
          isCurrent={+layoutOption === i + 1}
        />
      ))}
    </div>
  );
};

export default LayoutSelector;
