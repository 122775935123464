import React from "react";
import "./index.css";
import cn from "classnames";
import Textarea from "../../../layout/Textarea/Textarea";
import ImageUpload from "../../../layout/ImageUpload/index";
import {newsletterLayoutTypes, logoHyperskill} from "../../../data/constants";
import {checkIfIDE} from "../../../libs/tools";
import {getLogoUrl} from "../../../libs/tools";
import Icon from "../../../layout/Icon";
import BgTransformer from "../../../layout/BgTransformer";
import {useSetting} from "../../../contexts/setting";
import {useEdits} from "../../../contexts/edits";
import {useImages} from "../../../contexts/images";

const Voucher = ({id, zoom}) => {
  const {product, layoutOption, iconOption, type} = useSetting();
  const {bgUrl} = useImages();
  const {titles, subtitles, dates, badges, links} = useEdits();
  const logoURL = getLogoUrl(product.value, bgUrl, type.value);
  const title = titles[id] || titles.main;
  const date = dates[id] || dates.main;
  const subtitle = subtitles[id] || subtitles.main;
  const badge = badges[id] || badges.main;
  const link = links[id] || links.main;
  const isNewsletter = newsletterLayoutTypes.includes(type.value);
  const newsletterClass = isNewsletter ? "newsletter" : "";
  const templateClass = cn("template", `layout-${layoutOption}`, newsletterClass);
  const logoClass = cn("logo", {ide: checkIfIDE(product.value, type.value)});
  return (
    <>
      <div id={id} className={templateClass} style={{"--zoom-level": zoom}}>
        <div>
          <BgTransformer src={bgUrl} id={id} />

          <div className="content">
            <header className="template-header">
              <div className={logoClass}>
                <img src={logoURL} alt="background" />
              </div>
              {["JB", "EDU", "MP", "GDV", "DN"].includes(product.value) ? (
                ""
              ) : (
                <div className="product-name">{product.label}</div>
              )}
              {product.value === "EDU" && <img src={logoHyperskill} alt="background" />}
            </header>

            <div className="title-wrap">
              <h1 className="title">
                <Textarea id={id} text={title} />
              </h1>
              <h2 className="subtitle">
                <Textarea id={id} text={subtitle} />
              </h2>
            </div>
            <div className="badge">
              <Textarea id={id} text={badge} inline={true} />
            </div>

            <footer>
              <div className="date">
                <Textarea id={id} text={date} inline={true} />
              </div>
              <div className="link">
                <Textarea id={id} text={link} inline={true} />
              </div>
            </footer>
          </div>
        </div>
      </div>
    </>
  );
};

export default Voucher;
