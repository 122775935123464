import {useEffect, useState} from "react";
import {templates} from "../../data/templates.js";
import {useEdits} from "../../contexts/edits.js";
import {isEmptyObj} from "../../libs/tools";
import {useSetting} from "../../contexts/setting";
import {PLACEHOLDERS} from "../../data/constants";
import cn from "classnames";
import {findChannelObj} from "../../data/products";
import TemplateControls from "../../layout/TemplateControls";

const Social = () => {
  const {fontOption, visible, layoutOption, сhannel} = useSetting();
  const {editTitles, titles, badges, editBadges, editEaps, eaps} = useEdits();
  const [defaultTitles, setDefaultTitles] = useState(PLACEHOLDERS[fontOption].title);
  const [defaultButtons, setDefaultButtons] = useState(PLACEHOLDERS[fontOption].button);
  const [defaultEaps, setDefaultEaps] = useState(PLACEHOLDERS[fontOption].eap);
  const [defaultEmailNames, setDefaultEmailNames] = useState(PLACEHOLDERS[fontOption].emailName);

  useEffect(() => {
    if (isEmptyObj(titles)) {
      editTitles(defaultTitles, "main", false);
    }
    if (isEmptyObj(badges)) {
      editBadges(defaultButtons, "main", false);
    }
    if (isEmptyObj(eaps)) {
      editEaps(defaultEaps, "main", false);
    }
  }, []);

  const filteredTemplates = (array) => {
    return array.filter((template) => {
      const channelData = findChannelObj("email");
      return channelData?.layoutsConfig[layoutOption]?.includes(template.id);
    })
  }

  return filteredTemplates(templates.email).map((item) => (
    <div
      key={item.id}
      className={cn("template-wrap", {hidden: !visible.some((x) => x === item.id)})}
    >
      <div className="template-annotation">
        <span className="label">{`${item.label} (${item.dimensions})`}</span>
        <TemplateControls id={item.id} />
      </div>
      {item.getComponent()}
    </div>
  ));
};

export default Social;
