import {useMemo} from "react";
import {useParams} from "react-router-dom";

import {PRODUCTS} from "data/products";
import {DEFAULT_PRODUCT} from "data/constants";

export function useProductFromParams() {
  const {product: productFromParams} = useParams();
  return useMemo(() => {
    return PRODUCTS.find((product) => product.value === productFromParams) ?? DEFAULT_PRODUCT;
  }, [productFromParams]);
}
