import React from "react";
import "./index.css";
import Textarea from "../../../layout/Textarea/Textarea";
import {UI} from "../../../data/constants";
import BgTransformer from "../../../layout/BgTransformer";
import {useSetting} from "../../../contexts/setting";
import {useEdits} from "../../../contexts/edits";
import cn from "classnames";
import Icon from "../../../layout/Icon";
import ImageUpload from "../../../layout/ImageUpload";
import {useImages} from "../../../contexts/images";

const InstagramCountdownLivestream = ({ id, zoom, }) => {
  const { product, layoutOption, iconOption } = useSetting();
  const { names, positions } = useEdits();
  const { bgUrl } = useImages();
  const position = positions[id] || positions.main;
  const name = names[id] || names.main;
  const templateClass = cn("template", `layout-${layoutOption}`);

  return (
    <>
      <div id={id} className={templateClass} style={{"--zoom-level": zoom}}>
        <div>
          <BgTransformer src={bgUrl} id={id} />

          <div className="content">
            <div className="name">
              <Textarea id={id} text={name} />
            </div>
            <div className="position">
              <Textarea id={id} text={position} />
            </div>
            <div className="upload-wrap">
              <ImageUpload id={id} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InstagramCountdownLivestream;
