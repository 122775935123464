import React from "react";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

import App from "App";
import {EditsContextProvider} from "contexts/edits";
import {ImagesContextProvider} from "contexts/images";
import {SettingContextProvider} from "contexts/setting";

const queryClient = new QueryClient();

export const AppRoot = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <EditsContextProvider>
        <ImagesContextProvider>
          <SettingContextProvider>
            <App />
          </SettingContextProvider>
        </ImagesContextProvider>
      </EditsContextProvider>
    </QueryClientProvider>
  );
};
