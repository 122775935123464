import {AppRoot} from "AppRoot";
import React from "react";
import {createBrowserRouter, Navigate, redirect} from "react-router-dom";

import Email from "pages/Email";
import Internal from "pages/Internal";
import LandingPage from "pages/LandingPage";
import Livestream from "pages/Livestream";
import Social from "pages/Social";
import Print from "pages/Print";
import {DEFAULT_PRODUCT, DEFAULT_PRODUCTS_MAPPING} from "data/constants";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <AppRoot />,
    children: [
      {
        index: true,
        element: <Navigate to={`/social/${DEFAULT_PRODUCT.value}`} replace />,
      },
      {
        path: "social",
        element: <Navigate to={`/social/${DEFAULT_PRODUCTS_MAPPING.social}`} replace />,
      },
      {
        path: "livestream",
        element: <Navigate to={`/livestream/${DEFAULT_PRODUCTS_MAPPING.livestream}`} replace />,
      },
      {
        path: "internal",
        element: <Navigate to={`/internal/${DEFAULT_PRODUCTS_MAPPING.internal}`} replace />,
      },
      {
        path: "landingpage",
        element: <Navigate to={`/landingpage/${DEFAULT_PRODUCTS_MAPPING.landingpage}`} replace />,
      },
      {
        path: "email",
        element: <Navigate to={`/email/${DEFAULT_PRODUCTS_MAPPING.email}`} replace />,
      },
      {
        path: "print",
        element: <Navigate to={`/print/${DEFAULT_PRODUCTS_MAPPING.print}`} replace />,
      },
      {
        path: "social/:product/:id?",
        element: <Social />,
      },
      {
        path: "livestream/:product/:id?",
        element: <Livestream />,
      },
      {
        path: "internal/:product/:id?",
        element: <Internal />,
      },
      {
        path: "landingpage/:product/:id?",
        element: <LandingPage />,
      },
      {
        path: "email/:product/:id?",
        element: <Email />,
      },
      {
        path: "print/:product/:id?",
        element: <Print />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
]);
