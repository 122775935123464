import { useState, useCallback } from "react";

/**
 * Custom hook for copying the current URL to clipboard
 * @returns {Object} Object containing copyButtonText state and copyUrlToClipboard function
 */
export function useCopyUrl() {
  const [copyButtonText, setCopyButtonText] = useState("Copy link");

  const copyUrlToClipboard = useCallback(() => {
    const currentUrl = window.location.href;
    navigator.clipboard.writeText(currentUrl)
      .then(() => {
        console.log("URL copied to clipboard:", currentUrl);
        setCopyButtonText("Copied!");
        setTimeout(() => {
          setCopyButtonText("Copy link");
        }, 3000);
      })
      .catch(err => {
        console.error("Failed to copy URL to clipboard:", err);
        setCopyButtonText("Copy failed");
        setTimeout(() => {
          setCopyButtonText("Copy link");
        }, 3000);
      });
  }, []);

  return { copyButtonText, copyUrlToClipboard };
}
