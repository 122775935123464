import {useMemo} from "react";
import {useLocation, matchPath} from "react-router-dom";

import {CHANNELS} from "data/products";

const DEFAULT_CHANNEL = {label: CHANNELS[0].label, value: CHANNELS[0].value};

const PATH_PATTERNS = ["/:channel/:product", "/:channel/:product/:id"];

function findFirstMatchingPath(paths, currentPath) {
  for (const path of paths) {
    const match = matchPath(path, currentPath);
    if (match) {
      return match;
    }
  }
  return null;
}

export function useChannelFromParams() {
  const {pathname} = useLocation();
  return useMemo(() => {
    const match = findFirstMatchingPath(PATH_PATTERNS, pathname);
    const channelParam = match?.params?.channel ?? null;

    const channel = CHANNELS.find((c) => c.value === channelParam);
    return channel || DEFAULT_CHANNEL;
  }, [pathname]);
}
