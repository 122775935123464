import {useEffect, useState} from "react";
import {templates} from "../../data/templates.js";
import {useEdits} from "../../contexts/edits.js";
import {isEmptyObj} from "../../libs/tools";
import {useSetting} from "../../contexts/setting";
import {PLACEHOLDERS} from "../../data/constants";
import cn from "classnames";
import TemplateControls from "../../layout/TemplateControls";

const Print = () => {
  const {fontOption, visible, product, type} = useSetting();
  const [defaultTitles, setDefaultTitles] = useState(PLACEHOLDERS[fontOption].voucher_title);
  const [defaultSubtitles, setDefaultSubtitles] = useState(
    PLACEHOLDERS[fontOption].voucher_subtitle
  );
  const [defaultDates, setDefaultDates] = useState(PLACEHOLDERS[fontOption].voucher_date);
  const [defaultBadges, setDefaultBadges] = useState(PLACEHOLDERS[fontOption].discount);
  const [defaultLinks, setDefaultLinks] = useState(PLACEHOLDERS[fontOption].voucher_link);
  const {
    editTitles,
    titles,
    subtitles,
    dates,
    badges,
    editDates,
    editSubtitles,
    editBadges,
    links,
    editLinks,
  } = useEdits();

  useEffect(() => {
    if (isEmptyObj(titles)) {
      editTitles(defaultTitles, "main", false);
    }

    if (isEmptyObj(links)) {
      editLinks(defaultLinks, "main", false);
    }

    if (isEmptyObj(subtitles)) {
      editSubtitles(defaultSubtitles, "main", false);
    }

    if (isEmptyObj(dates)) {
      editDates(defaultDates, "main", false);
    }

    if (isEmptyObj(badges)) {
      editBadges(defaultBadges, "main", false);
    }
  }, []);

  return templates.print.map((item) => (
    <div
      key={item.id}
      className={cn("template-wrap", {hidden: !visible.some((x) => x === item.id)})}
    >
      <div className="template-annotation">
        <span className="label">{`${item.label} (${item.dimensions})`}</span>
        <TemplateControls id={item.id} />
      </div>
      {item.getComponent()}
    </div>
  ));
};

export default Print;
