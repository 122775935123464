import React from "react";
import "./index.css";
import cn from "classnames";
import ImageUpload from "../../../layout/ImageUpload/index";
import {useSetting} from "../../../contexts/setting";
import {useImages} from "../../../contexts/images";

const EmailPhotoLivestream = ({id, zoom}) => {
  const {layoutOption} = useSetting();
  const {bgUrlMini} = useImages();
  const templateClass = cn("template", `layout-${layoutOption}`);

    const bgStyle = {
        backgroundImage: `url(${bgUrlMini})`,
    };

    return (
        <>
            <div id={id} className={templateClass} style={{"--zoom-level": zoom}}>
                <div className="bg" style={bgStyle}>
                    <div className="content">
                        <div className="upload-wrap">
                            <svg
                                width="0"
                                height="0"
                                style={{ position: 'absolute', visibility: 'visible' }}
                            >
                                <defs>
                                    <mask id={`upload-mask`}>
                                        <path fill="white" d="m160 0h-76.8c-12.6 0-24.1 7-30 18.1l-1.6 3c-4.2 8-12.4 12.9-21.4 12.9h-14.2c-8.8 0-16 7.2-16 16v144c0 8.8 7.2 16 16 16h16v16c0 8.8 7.2 16 16 16h16v16c0 8.8 7.2 16 16 16h16v16c0 8.8 7.2 16 16 16h144c8.8 0 16-7.2 16-16v-290z"/>
                                    </mask>
                                </defs>
                            </svg>
                            <div className="image-mask-container">
                                <ImageUpload id={id}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EmailPhotoLivestream;
