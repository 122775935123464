import React, {useState} from "react";
import "./index.css";
import cn from "classnames";
import Textarea from "../../../layout/Textarea/Textarea";
import ImageUpload from "../../../layout/ImageUpload/index";
import { UI } from "../../../data/constants";
import Icon from "../../../layout/Icon";
import BgTransformer from "../../../layout/BgTransformer";
import {useSetting} from "../../../contexts/setting";
import {useEdits} from "../../../contexts/edits";
import {useImages} from "../../../contexts/images";

const EmailBannerLivestream = ({id, zoom}) => {
  const {product, layoutOption, iconOption} = useSetting();
  const {bgUrl} = useImages();
  const [isInline, setIsInline] = useState(true);
  const { titles, names } = useEdits();
  const title = titles[id] || titles.main;
  const name = names[id] || names.main;
  const iconUrl = `${UI}/Icons/${iconOption}.svg`;
  const templateClass = cn("template", `layout-${layoutOption}`);

  return (
    <>
      <div id={id} className={templateClass} style={{"--zoom-level": zoom}}>
        <div>
          <BgTransformer src={bgUrl} id={id} />

          <div className="content">
            <h1 className="title">
              <Textarea id={id} text={title} />
            </h1>
            <h2 className="name">
              <Textarea id={id} text={name} />
            </h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailBannerLivestream;
