import React, {useState} from "react";
import "./index.css";
import cn from "classnames";
import Textarea from "../../../layout/Textarea/Textarea";
import ImageUpload from "../../../layout/ImageUpload/index";
import { UI, KRAKEN_COLORS} from "../../../data/constants";
import {sanitizeText} from "../../../libs/tools";
import BgTransformer from "../../../layout/BgTransformer";
import {useSetting} from "../../../contexts/setting";
import {useEdits} from "../../../contexts/edits";
import {useImages} from "../../../contexts/images";

const StreamYardLivestream = ({ id, zoom,  }) => {
  const { product, layoutOption, palettes } = useSetting();
  const { bgUrl } = useImages();
  const [isInline] = useState(true);
  const {titles} = useEdits();
  const title = sanitizeText(titles[id] || titles.main || '');
  const templateClass = cn("template", `layout-${layoutOption}`);
  const colName = KRAKEN_COLORS[product.value];
  const col = palettes[colName] ? palettes[colName][0].hex : "transparent";
  const gradientStyle = {
    background: `linear-gradient(to right, ${col}, black)`,
  };

  return (
    <>
      <div id={id} className={templateClass} style={{"--zoom-level": zoom}}>
        <div>
          <BgTransformer src={bgUrl} id={id} />

          <div className="content">
            <div className="title" style={gradientStyle}>
              <Textarea id={id} text={title} inline={isInline} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StreamYardLivestream;
