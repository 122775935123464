import {DEFAULT_BACKGROUND_DATA} from "./data/backgrounds";
import {createOrderedArray, sortValuesByKey} from "./libs/tools";
import {logDOM} from "@testing-library/react";

export const URLS = {
  KRAKENUI: `https://resources.jetbrains.com/cai/brand-data/products.json`,
  HUANGHE: "https://huanghe-api.labs.jb.gg",
  KRAKEN_SAVE: "https://kraken.labs.jb.gg",
  KRAKEN: "https://kraken-public.labs.jb.gg",
};

// Cache for loaded backgrounds
const backgroundCache = new Map();

export class HTTPError extends Error {
  constructor(response, data = {}) {
    super(`${response.status} ${response.statusText || ""}`);
    this.data = data;
    this.status = response.status;
  }
}

export async function request(url, options = {}) {
  const initialHeaders = {
    "Content-Type": "application/json;charset=utf-8",
  };

  try {
    const response = await fetch(url, {
      headers: {...initialHeaders, ...options.headers},
      ...options,
    });
    const contentType = response.headers.get("content-type");

    let data = null;
    if (contentType && contentType.includes("application/json")) {
      data = await response.json();
    } else {
      data = await response.text();
    }

    if (!response.ok) {
      throw new HTTPError(response, data);
    }

    return data;
  } catch (error) {
    console.error("Request failed:", error);
    throw error;
  }
}

export const API = {
  fetchPalettes() {
    return request(URLS.KRAKENUI);
  },

  // getBackgrounds: async (channel, type) => {
  //   try {
  //     const response = await fetch(`${URLS.KRAKEN}/tiles/get_tilesets?prefix=Neurobaker/Backgrounds/${channel}/${type}`, {
  //       method: 'GET',
  //       mode: 'cors',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       }
  //     });
  //     let result = await response.json();
  //     return sortValuesByKey(result.tile_sets);
  //   } catch(err) {
  //     console.error(err); // TypeError: failed to fetch
  //   }
  // },

  getBackgrounds: async (channel, t, options = {progressive: false, priorityIndex: 1}) => {
    const type = t || "general";
    const cacheKey = `${channel}/${type}`;
    try {
      // If we're in progressive mode and have cache, return it immediately
      if (options.progressive && backgroundCache.has(cacheKey)) {
        return backgroundCache.get(cacheKey);
      }

      const response = await fetch(
        `${URLS.KRAKEN}/tiles/get_tilesets?prefix=Neurobaker/Backgrounds/${channel}/${type}`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let result = await response.json();
      const sortedBgs = sortValuesByKey(result.tile_sets);

      if (options.progressive) {
        // In progressive mode, we first return only the priority background
        const priorityBg = sortedBgs[options.priorityIndex - 1]
          ? [sortedBgs[options.priorityIndex - 1]]
          : [];
        backgroundCache.set(cacheKey, priorityBg);

        // Then load the rest of the backgrounds asynchronously
        setTimeout(async () => {
          backgroundCache.set(cacheKey, sortedBgs);
          if (options.onUpdate) {
            options.onUpdate(sortedBgs);
          }
        }, 100);

        return priorityBg;
      }

      // In non-progressive mode, return all backgrounds
      backgroundCache.set(cacheKey, sortedBgs);
      return sortedBgs;
    } catch (err) {
      console.error(err); // TypeError: failed to fetch
      return backgroundCache.get(cacheKey) || [];
    }
  },

  clearBackgroundCache: () => {
    backgroundCache.clear();
  },

  saveScene(params) {
    return request(`${URLS.KRAKEN_SAVE}/scene/save?prefix=neurobaker`, {
      method: "POST",
      body: JSON.stringify(params),
    });
  },

  async fetchScene(sceneId) {
    return request(`${URLS.KRAKEN}/scene/load?scene_id=${sceneId}&prefix=neurobaker`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
    });
  },

  getParams: async (hashStr) => {
    try {
      const response = await fetch(
        `${URLS.KRAKEN}/scene/load?scene_id=${hashStr}&prefix=neurobaker`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
        }
      );
      return await response.json();
    } catch (err) {
      console.error(err); // TypeError: failed to fetch
    }
  },

  getAllTransforms: async (hashArray, defaultArray) => {
    for (let i = 0; i < hashArray.length; i++) {
      if (!hashArray[i]) {
        // console.log("hashArray[i]", hashArray[i]);
        hashArray[i] = defaultArray[i] || "null";
      }
    }

    // console.log("hash array sending:", key, hashArray);

    const obj = {
      scene_hashes: hashArray,
      scene_prefix: "neurobaker",
    };
    const body = JSON.stringify(obj);

    if (!hashArray) {
      return DEFAULT_BACKGROUND_DATA;
    } else {
      try {
        const response = await fetch(`${URLS.KRAKEN}/scene/load/v2`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          body,
        });
        const result = await response.json();
        const ordered = createOrderedArray(result?.scenes, hashArray);
        // console.log("initial scenes: ", result?.scenes);
        // console.log("hash array: ", hashArray);
        // console.log("ordered: ", ordered);
        const extractedList = ordered.map((scene, i) => {
          // if (i === 29) console.log("scene 29", scene);
          if (scene === null) {
            return null;
          } else
            return {
              x: scene.bgX,
              y: scene.bgY,
              scale: scene.bgScale,
              hash: hashArray[i],
            };
        });
        return extractedList;
      } catch (err) {
        console.error("looks like empty hashes in array", err); // TypeError: failed to fetch
        return null;
      }
    }
  },

  getTransforms: async (hashStr) => {
    // console.log('getting transforms', hashStr)
    if (!hashStr) {
      return DEFAULT_BACKGROUND_DATA;
    } else {
      try {
        const response = await fetch(
          `${URLS.KRAKEN}/scene/load?scene_id=${hashStr}&prefix=neurobaker`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
            },
          }
        );
        const result = await response.json();
        const {bgScale, bgX, bgY} = result;
        return {x: bgX, y: bgY, scale: bgScale};
      } catch (err) {
        console.error(err); // TypeError: failed to fetch
        return DEFAULT_BACKGROUND_DATA;
      }
    }
  },

  shorten: async (value) => {
    try {
      const obj = {text: value};
      const response = await fetch(`${URLS.HUANGHE}/tools/shorten_text`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(obj),
      });
      const result = await response.json();
      return result.shortened_text;
    } catch (err) {
      console.error(err); // TypeError: failed to fetch
    }
  },

  translate: async (value, target_language) => {
    try {
      const obj = {
        text: value,
        target_language: target_language,
      };
      const response = await fetch(`${URLS.HUANGHE}/tools/translate_text`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(obj),
      });
      const result = await response.json();
      return result.translated_text;
    } catch (err) {
      console.error(err);
    }
  },
};
