import React, {useContext, useState, createContext, useCallback} from "react";

export const Images = createContext({
  images: {},
  editImages: () => {},
});

export const ImagesContextProvider = ({children}) => {
  const [images, setImages] = useState({});
  const [customBg, setCustomBg] = useState("");
  const [bgUrl, setBgUrl] = useState("");
  const [bgUrl2x, setBgUrl2x] = useState("");
  const [bgUrlMini, setBgUrlMini] = useState("");
  const [allBgUrls, setAllBgUrls] = useState([]);
  const [bgHashes, setBgHashes] = useState({});
  const [bgScale, setBgScale] = useState({});
  const [bgX, setBgX] = useState({});
  const [bgY, setBgY] = useState({});
  const [bgData, setBgData] = useState({});
  const [uploadScale, setUploadScale] = useState({});
  const [uploadX, setUploadX] = useState({});
  const [uploadY, setUploadY] = useState({});
  const [renderKey, setRenderKey] = useState(0);

  const editAllBgUrls = useCallback((value) => {
    setAllBgUrls(value);
  }, []);

  const editBgHashes = useCallback((value) => {
    setBgHashes(value);
  }, []);

  const editBgData = useCallback((value) => {
    setBgData(value);
  }, []);

  const removeImages = useCallback(() => {
    setImages({});
  }, []);

  const editBgUrl2x = useCallback((value) => {
    setBgUrl2x(value);
  }, []);

  const editBgUrlMini = useCallback((value) => {
    setBgUrlMini(value);
  }, []);

  const editBgUrl = useCallback((value) => {
    setBgUrl(value);
  }, []);

  const loadImages = useCallback((images) => {
    setImages(images);
  }, []);

  const editCustomBg = useCallback((value) => {
    setCustomBg(value);
  }, []);

  const editTransform = useCallback((x, y, s) => {
    setBgX(x);
    setBgY(y);
    setBgScale(s);
  }, []);

  const editUploadTransform = useCallback((x, y, s) => {
    setUploadX(x);
    setUploadY(y);
    setUploadScale(s);
  }, []);

  const rerenderBg = useCallback(() => {
    setRenderKey((prev) => prev + 1);
  }, []);

  const editUploadY = useCallback((id, value) => {
    setUploadY((curY) => ({...curY, [id]: value}));
  }, []);

  const editUploadX = useCallback((id, value) => {
    setUploadX((curX) => ({...curX, [id]: value}));
  }, []);

  const editUploadScale = useCallback((id, value) => {
    setUploadScale((curScale) => ({...curScale, [id]: value}));
  }, []);

  const editBgY = useCallback((id, value) => {
    setBgY((curY) => ({...curY, [id]: value}));
  }, []);

  const editBgX = useCallback((id, value) => {
    setBgX((curX) => ({...curX, [id]: value}));
  }, []);

  const editBgScale = useCallback((id, value) => {
    setBgScale((curScale) => ({...curScale, [id]: value}));
  }, []);

  const editImages = useCallback((value, id, isLocked = false) => {
    const currentId = isLocked ? id : "main";
    setImages((curImages) => ({...curImages, [currentId]: value}));
  }, []);

  return (
    <Images.Provider
      value={{
        images,
        bgScale,
        bgX,
        bgY,
        renderKey,
        customBg,
        uploadScale,
        uploadX,
        uploadY,
        bgUrl,
        bgUrl2x,
        bgData,
        allBgUrls,
        bgUrlMini,
        bgHashes,
        editBgUrl,
        loadImages,
        removeImages,
        editCustomBg,
        rerenderBg,
        editImages,
        editTransform,
        editBgScale,
        editBgY,
        editBgX,
        editUploadTransform,
        editUploadScale,
        editUploadY,
        editUploadX,
        editBgData,
        editAllBgUrls,
        editBgUrl2x,
        editBgUrlMini,
        editBgHashes,
      }}
    >
      {children}
    </Images.Provider>
  );
};

export function useImages() {
  const context = useContext(Images);
  if (context === undefined) {
    throw new Error("Images.Context must be used within the Images.Provider");
  }
  return context;
}
