import React from "react";
import "./index.css";
import cn from "classnames";
import Textarea from "../../../layout/Textarea/Textarea";
import ImageUpload from "../../../layout/ImageUpload";
import {UI, newsletterLayoutTypes} from "../../../data/constants";
import {getLogoUrl} from "../../../libs/tools";
import {checkIfIDE} from "../../../libs/tools";
import Icon from "../../../layout/Icon";
import BgTransformer from "../../../layout/BgTransformer";
import {useSetting} from "../../../contexts/setting";
import {useEdits} from "../../../contexts/edits";
import {useImages} from "../../../contexts/images";

const LandingPage = ({ id, zoom,  }) => {
  const { product, layoutOption, iconOption, locked, visible, type } = useSetting();
  const { bgUrl } = useImages();
  const { titles, subtitles, dates } = useEdits();
  const logoURL = getLogoUrl(product.value, bgUrl, type.value);
  const title = titles[id] || titles.main;
  const date = dates[id] || dates.main;
  const subtitle = subtitles[id] || subtitles.main;
  const iconUrl = `${UI}/Icons/${iconOption}.svg`;
  const isNewsletter = newsletterLayoutTypes.includes(type.value);
  const newsletterClass = isNewsletter ? "newsletter" : "";
  const templateClass = cn("template", `layout-${layoutOption}`, newsletterClass);
  const contentClass = cn("content", `layout-${layoutOption}`);
  const uploadClass = cn("upload-wrap", `layout-${layoutOption}`);
  const logoClass = cn("logo", {ide: checkIfIDE(product.value)});
  const isLocked = locked.some((elem) => elem === id);
  const isVisible = visible.some((elem) => elem === id);

  return (
    <>
      <div id={id} className={templateClass} style={{"--zoom-level": zoom}}>
        <div>
          <BgTransformer src={bgUrl} id={id} />

          <div className="content">
            <header className="template-header">
              <div className={logoClass}>
                <img src={logoURL} alt="background"/>
              </div>
            </header>

            <div className="title-wrap">
              <h1 className="title">
                <Textarea id={id} text={title}/>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
