export const DEFAULT_PRODUCT = {label: "JetBrains", value: "JB"};

export const DEFAULT_PRODUCTS_MAPPING = {
  social: DEFAULT_PRODUCT.value,
  livestream: "IJ",
  internal: "IH",
  email: DEFAULT_PRODUCT.value,
  landingpage: DEFAULT_PRODUCT.value,
  print: "EDU",
};

export const options = [{label: "Social Media & Blog", value: "social"}];

export const KRAKEN_URL = "https://d3r50642m20jlp.cloudfront.net/kraken/tiles";
export const THUMBNAILS_URL = "https://d3r50642m20jlp.cloudfront.net/kraken/gdrive_thumbnails";

export const MANUAL_LINK = "https://youtrack.jetbrains.com/articles/DSGN-A-55/CoverBaker";
export const FEEDBACK_LINK = "https://jetbrains.slack.com/archives/C07JLAY1MFB";
export const PREPARE_PHOTO_LINK = "https://slack.com/app_redirect?app=A056WC694N6";

export const NEUROBAKER = `${KRAKEN_URL}/Neurobaker`;

export const UPLOAD_ICON_URL = `${NEUROBAKER}/UI/upload.svg`;

export const EYE_FALSE = `${NEUROBAKER}/UI/Icons/eye-false.svg`;
export const EYE_TRUE = `${NEUROBAKER}/UI/Icons/eye-true.svg`;
export const IMG = `${NEUROBAKER}/UI/Icons/img.svg`;
export const PORTRAIT = `${NEUROBAKER}/UI/Icons/portrait.svg`;
export const ZOOMIN = `${NEUROBAKER}/UI/Icons/plus.svg`;
export const ZOOMOUT = `${NEUROBAKER}/UI/Icons/minus.svg`;
export const RESET = `${NEUROBAKER}/UI/Icons/reset.svg`;
export const TRASH_BIN = `${NEUROBAKER}/UI/Icons/trash-bin.svg`;

export const LOCK_FALSE = `${NEUROBAKER}/UI/Icons/sync.svg`;
export const LOCK_TRUE = `${NEUROBAKER}/UI/Icons/unsync.svg`;
export const DOWNLOAD_ICON_URL = `${NEUROBAKER}/UI/download.svg`;

export const GENERAL_BG = `${NEUROBAKER}/Backgrounds/social/general/1`;
export const BACKGROUNDS = `${NEUROBAKER}/Backgrounds`;

export const UI = `${NEUROBAKER}/UI`;
export const logoIconWhite = `${NEUROBAKER}/Logos/JETBRAINS_white_icon_white.svg`;
export const logoNorm = `${NEUROBAKER}/Logos/JETBRAINS_logo_white.svg`;
export const logoNormInverted = `${NEUROBAKER}/Logos/JETBRAINS_logo_white_inverted.svg`;
export const logoWhite = `${NEUROBAKER}/Logos/JETBRAINS_white_logo_white.svg`;
export const logoIDE = `${NEUROBAKER}/Logos/JETBRAINS_IDEs_logo_white.svg`;
export const logoNormBlack = `${NEUROBAKER}/Logos/JETBRAINS_logo_black.svg`;
export const logoNormBlackInverted = `${NEUROBAKER}/Logos/JETBRAINS_logo_black_inverted.svg`;
export const logoIDEBlack = `${NEUROBAKER}/Logos/JETBRAINS_IDEs_logo_black.svg`;
export const logoIDEWhite = `${NEUROBAKER}/Logos/JETBRAINS_IDEs_white_logo_white.svg`;
export const logoAcademyNorm = `${NEUROBAKER}/Logos/JetBrains_Academy_logo_white.svg`;
export const logoAcademyBlack = `${NEUROBAKER}/Logos/JetBrains_Academy_logo_black.svg`;
export const logoGameDevNorm = `${NEUROBAKER}/Logos/JetBrains_GameDev_logo_white.svg`;
export const logoGameDevBlack = `${NEUROBAKER}/Logos/JetBrains_GameDev_logo_black.svg`;
export const logoMarketplaceNorm = `${NEUROBAKER}/Logos/JetBrains_Marketplace_logo_white.svg`;
export const logoMarketplaceBlack = `${NEUROBAKER}/Logos/JetBrains_Marketplace_logo_black.svg`;
export const logoDotNETNorm = `${NEUROBAKER}/Logos/JetBrains_dotNET_logo_white.svg`;
export const logoDotNETBlack = `${NEUROBAKER}/Logos/JetBrains_dotNET_logo_black.svg`;
export const logoYouTrackBlack = `${NEUROBAKER}/Logos/YouTrack_logo_black.svg`;
export const logoHyperskill = `${NEUROBAKER}/Logos/hyperskill.svg`;

export const newsletterLayoutTypes = ["java-annotated", "php-annotated", "newsletter"];

export const KRAKEN_COLORS = {
  QA: "Aqua",
  // "Arend",
  BDT: "BigDataTools",
  CL: "CLion",
  CWM: "CodeWithMe",
  DG: "DataGrip",
  DS: "DataSpell",
  DL: "Datalore",
  // "Deft",
  // "Dekaf",
  // "Draft",
  EDU: "EduProduct",
  FL: "Fleet",
  // "Gateway",
  GO: "GoLand",
  GR: "Grazie",
  HUB: "Hub",
  // "IdeaVim",
  IJ: "IntellijIDEA",
  // "IntellijIDEACommunity",
  // "IntellijIDEAEdu",
  // "IntellijIDEAUltimate",
  // "IntellijRust",
  // "JBAcademy",
  JB: "JetBrains",
  KT: "Kotlin",
  // "Ktor",
  // "LicenseVault",
  MPS: "MPS",
  // "Marketplace",
  // "Mono",
  PS: "PHPStorm",
  // "PiDay",
  // "Projector",
  PC: "PyCharm",
  // "PyCharmCommunity",
  // "PyCharmDataScience",
  // "PyCharmEdu",
  QT: "QATools",
  QD: "Qodana",
  // "RPlugin",
  RS: "ReSharper",
  RScpp: "ReSharperCpp",
  // "RescUi",
  RD: "Rider",
  // "RiderFlow",
  // "RingUi",
  RM: "RubyMine",
  // "RustRover",
  SP: "Space",
  TC: "TeamCity",
  // "TestIDE",
  TB: "Toolbox",
  WS: "WebStorm",
  // "WritersIDE",
  // "Xodus",
  // "XodusDNQ",
  YT: "YouTrack",
  DC: "dotCover",
  DM: "dotMemory",
  DP: "dotPeek",
  DT: "dotTrace",
  // "dotUltimate",
};

export const fontSizes = [
  {label: "-2", value: 0.8},
  {label: "-1", value: 0.9},
  {label: "standard font size", value: 1},
  {label: "+1", value: 1.1},
  {label: "+2", value: 1.2},
  // {label: "giant", value: 2},
];

export const IDEs = [
  "QA",
  "CL",
  "DG",
  "IJ",
  "PC",
  "RM",
  "WS",
  "DS",
  "GO",
  "PS",
  "RD",
  "RS",
  "RScpp",
  "RR",
  "WR",
  "IDEs",
];

export const ICONS = ["key", "book", "diamond", "java", "track", "lamp"];

export const TAGS = [
  {
    value: "soon",
    label: "Coming soon",
  },
  {
    value: "outdated",
    label: "Outdated",
  },
  {
    value: "latest",
    label: "Latest",
  },
];

export const TEXT_CLASSNAMES = [
  "title",
  "subtitle",
  "name",
  "badge",
  "position",
  "date",
  "eap",
  "link",
];

export const PLACEHOLDERS = {
  en: {
    title: "Write your own text here",
    title_JB_newsletter: "JetBrains\nNewsletter",
    title_DN_newsletter: "dotInsights\n.NET\nNewsletter",
    title_TC_newsletter: "TeamCity\nPipelines\nPulse",
    title_PS_newsletter: "PHP\nAnnotated",
    title_IJ_newsletter: "Java\nAnnotated\nMonthly",
    title_MP_newsletter: "Busy Plugin\nDevelopers\nNewsletter",
    date: "Date",
    date_JB_newsletter: "Issue #",
    date_DN_newsletter: "Issue #",
    date_TC_newsletter: "Issue #",
    date_PS_newsletter: "Issue #",
    date_IJ_newsletter: "Issue #",
    date_MP_newsletter: "Q1 2025",
    subtitle: "And here write something else",
    position: "Position",
    episode: "Links",
    secondLine: "Links",
    thirdLine: "Links",
    name: "Host name",
    emailName: "Write the name here",
    livestream: "Livestream",
    button: "Button text",
    eap: "202X.X EAP",
    discount: "JetBrainsStudentTechQuizvol4yhzKXcxr",
    voucher_date: "Redeem before March 31, 2025",
    voucher_title: "1-Year Personal Subscription",
    voucher_subtitle: "100% Discount Code",
    voucher_link: "jb.gg/academy/quiz",
  },
  de: {
    title: "Schreiben Sie Ihren Text",
    date: "Datum",
    subtitle: "Und hier schreiben Sie etwas anderes",
    position: "Position",
    episode: "Links",
    secondLine: "Links",
    thirdLine: "Links",
    livestream: "Livestream",
    button: "Button text",
    name: "Host name",
    emailName: "Write the name here",
    eap: "202X.X EAP",
    discount: "JetBrainsStudentTechQuizvol4yhzKXcxr",
    voucher_date: "Redeem before March 31, 2025",
  },
  fr: {
    title: "Écrivez votre texte",
    date: "Date",
    subtitle: "Et ici écrivez quelque chose d'autre",
    position: "Position",
    episode: "Liens",
    secondLine: "Liens",
    thirdLine: "Liens",
    livestream: "Livestream",
    button: "Button text",
    name: "Host name",
    emailName: "Write the name here",
    eap: "202X.X EAP",
    discount: "JetBrainsStudentTechQuizvol4yhzKXcxr",
    voucher_date: "Redeem before March 31, 2025",
  },
  es: {
    title: "Escriba su texto",
    date: "Fecha",
    subtitle: "Y aquí escribe algo más",
    position: "Posición",
    episode: "Enlaces",
    secondLine: "Enlaces",
    thirdLine: "Enlaces",
    livestream: "Livestream",
    button: "Button text",
    name: "Host name",
    emailName: "Write the name here",
    eap: "202X.X EAP",
    discount: "JetBrainsStudentTechQuizvol4yhzKXcxr",
    voucher_date: "Redeem before March 31, 2025",
  },
  br: {
    title: "Escreva seu texto aqui",
    date: "Data",
    subtitle: "E aqui escreva algo mais",
    position: "Posição",
    episode: "Links",
    secondLine: "Links",
    thirdLine: "Links",
    livestream: "Livestream",
    button: "Button text",
    name: "Host name",
    emailName: "Write the name here",
    eap: "202X.X EAP",
    discount: "JetBrainsStudentTechQuizvol4yhzKXcxr",
    voucher_date: "Redeem before March 31, 2025",
  },
  jp: {
    title: "テキストを書いて。",
    date: "日付",
    subtitle: "そしてここに何か他のことを書いてください",
    position: "位置",
    episode: "リンク",
    secondLine: "リンク",
    thirdLine: "リンク",
    livestream: "Livestream",
    button: "Button text",
    name: "Host name",
    emailName: "Write the name here",
    eap: "202X.X EAP",
    discount: "JetBrainsStudentTechQuizvol4yhzKXcxr",
    voucher_date: "Redeem before March 31, 2025",
  },
  ch: {
    title: "在这里写下你自己的文本",
    date: "日期",
    subtitle: "然后在这里写点别的",
    position: "职位",
    episode: "链接",
    secondLine: "链接",
    thirdLine: "链接",
    livestream: "Livestream",
    button: "Button text",
    name: "Host name",
    emailName: "Write the name here",
    eap: "202X.X EAP",
    discount: "JetBrainsStudentTechQuizvol4yhzKXcxr",
    voucher_date: "Redeem before March 31, 2025",
  },
  kr: {
    title: "텍스트를 작성하세요",
    date: "날짜",
    subtitle: "그리고 여기에 다른 것을 쓰세요",
    position: "직위",
    episode: "링크",
    secondLine: "링크",
    thirdLine: "링크",
    livestream: "Livestream",
    button: "Button text",
    name: "Host name",
    emailName: "Write the name here",
    eap: "202X.X EAP",
    discount: "JetBrainsStudentTechQuizvol4yhzKXcxr",
    voucher_date: "Redeem before March 31, 2025",
  },
  tr: {
    title: "Kendi metnini buraya yaz",
    date: "Tarih",
    subtitle: "Ve buraya başka bir şey yaz",
    position: "Pozisyon",
    episode: "Bağlantılar",
    secondLine: "Bağlantılar",
    thirdLine: "Bağlantılar",
    livestream: "Livestream",
    button: "Button text",
    name: "Host name",
    emailName: "Write the name here",
    eap: "202X.X EAP",
    discount: "JetBrainsStudentTechQuizvol4yhzKXcxr",
    voucher_date: "Redeem before March 31, 2025",
  },
  ru: {
    title: "Напишите текст",
    date: "Дата",
    subtitle: "И здесь напишите что-нибудь ещё",
    position: "Позиция",
    episode: "Ссылки",
    secondLine: "Ссылки",
    thirdLine: "Ссылки",
    livestream: "Livestream",
    button: "Надпись на кнопке",
    name: "Имя хоста",
    emailName: "Write the name here",
    eap: "202X.X EAP",
    discount: "JetBrainsStudentTechQuizvol4yhzKXcxr",
    voucher_date: "Redeem before March 31, 2025",
  },
};
