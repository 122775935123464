import React from "react";
import {useState, useEffect} from "react";
import cn from "classnames";
import "./index.css";
import {Select} from "@rescui/select";
import Button from "@rescui/button";
import {Toggle} from "@rescui/toggle";
import {useTextStyles} from "@rescui/typography";
import {RS_COLOR_GREY_40} from "@rescui/colors";
import {CHANNELS, PRODUCTS} from "../../data/products.js";
import {useNavigate} from "react-router-dom";
import {getHashes} from "../../libs/tools";
import {useEdits} from "../../contexts/edits.js";
import {useSetting} from "../../contexts/setting.js";
import {useImages} from "../../contexts/images";
import BgSelector from "../BgSelector";
import LayoutSelector from "../LayoutSelector";
import IconSelector from "../IconSelector";
import FontSelector from "../FontSelector";
import {InfoIcon, MessageIcon} from "@rescui/icons";
import {API} from "../../api";
import {DEFAULT_BACKGROUND_DATA} from "../../data/backgrounds";
import {
  FEEDBACK_LINK,
  MANUAL_LINK,
  PREPARE_PHOTO_LINK,
  newsletterLayoutTypes,
  DEFAULT_PRODUCTS_MAPPING,
} from "data/constants";

const Toolbar = (props) => {
  const {handleExport, handleSaveScene, handlePDF, copyButtonText} = props;
  const textCn = useTextStyles();
  const navigate = useNavigate();
  const edits = useEdits();
  const {editBgHashes, bgHashes, editBgData, rerenderBg, editTransform, editAllBgUrls} =
    useImages();
  const {
    product,
    editBgOption,
    editProduct,
    channel,
    editChannel,
    type,
    editType,
    editLayoutOption,
    layoutOption,
    bgOption,
    hash,
    isDropzoneVisible,
    makeDropzoneVisible,
    layoutAmount,
    isExportLoading,
  } = useSetting();

  const [isFontToggle, setIsFontToggle] = useState(false);
  const [channelList, setChannelList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [bgAmount, setBgAmount] = useState(9);
  const [iconAmount, setIconAmount] = useState(6);
  const [isLoadingBackgrounds, setIsLoadingBackgrounds] = useState(false);

  const isToggleOnSocial = channel.value === "social" && [5, 6].includes(+layoutOption);
  const isToggleOnInternal = channel.value === "internal" && [5, 6].includes(+layoutOption);

  const saveSceneTrigger = () => {
    handleSaveScene();
  };

  const updateChannelList = () => {
    const list = CHANNELS.map((ch) => ({label: ch.label, value: ch.value}));
    setChannelList(list);
  };
  const updateProductList = (ch = channel, reset = false) => {
    const channelObject = CHANNELS.find((obj) => obj.value === ch.value);
    const requiredProducts = channelObject.products;
    const prodList = PRODUCTS.filter((p) =>
      requiredProducts.some((required) => required === p.value)
    );
    setProductList(prodList);
    const defaultProduct =
      prodList.find((productItem) => productItem.value === DEFAULT_PRODUCTS_MAPPING[ch.value]) ??
      prodList[0];

    if (reset) editProduct(defaultProduct);
  };

  const updateTypeList = (prod = product, reset = true) => {
    const channelObject = CHANNELS.find((ch) => ch.value === channel.value);
    if (channelObject.types) {
      const filtered = channelObject.types.filter((typeObj) =>
        typeObj.products.includes(prod.value)
      );
      const pureTypes = filtered.map((t) => ({label: t.label, value: t.value}));
      setTypeList(pureTypes);
      if (reset) editType(pureTypes[0] ?? {label: "", value: null});
    } else {
      setTypeList([]);
    }
  };

  const onChangeChannel = (value) => {
    editChannel(value);
    edits.reset();
    editBgOption("1");
    editLayoutOption("1");
    updateProductList(value, true);
    // Clear background cache when changing channel
    API.clearBackgroundCache();
    navigate(`/${value.value}/${DEFAULT_PRODUCTS_MAPPING[value.value]}`);
  };

  const onChangeProduct = (value) => {
    editProduct(value);
    editBgOption("1");
    editLayoutOption("1");
    edits.editProductNames({main: value.label});
    const nextUrl = `${channel.value}/${value.value}`;
    navigate(nextUrl);
  };

  const onChangeType = (value) => {
    editType(value);
    editBgOption("1");
    editLayoutOption("1");
    // Clear background cache when changing type
    API.clearBackgroundCache();
  };

  useEffect(() => {
    updateTypeList();
  }, [channel, product]);

  useEffect(() => {
    updateChannelList();
    updateProductList();
    updateTypeList();
  }, []);

  const getBgTransforms = async (bgs) => {
    const hashes = getHashes(bgs);

    editBgHashes(hashes);
    // console.log("initial hashes", hashes);

    if (Object.keys(hashes).length) {
      // Create an array of promises
      const promises = Object.keys(hashes).map((key) => {
        const hashArray = hashes[key];
        const defaultArray = hashes["default"];
        // console.log("HASHES TOTAL", hashes);
        // Return promise
        return API.getAllTransforms(hashArray, defaultArray).then((result) => ({
          [key]: result,
        }));
      });

      // Waiting for all promises to fulfill
      const results = await Promise.all(promises);

      // Final object, converted from array
      const output = results.reduce((acc, curr) => {
        const key = Object.keys(curr)[0]; // Get the key from the object
        acc[key] = curr[key]; // Add the key-value pair to the resulting object
        return acc;
      }, {});

      console.log("all transforms: ", output);
      // console.log(product.value);

      const isProductHashDetected = Object.keys(output).includes(product.value);
      const isNotScene = !hash & !window.location.hash;
      let coords = null;

      console.log("product hash detected: ", isProductHashDetected);

      if (isNotScene) {
        if (isProductHashDetected) {
          coords = output[product.value][bgOption - 1];
        }

        if (!coords) {
          coords = output["default"][bgOption - 1];
        }

        // console.log("loading bg transforms");
        // console.log("coords", coords);
        // console.log(product.value);
        // console.log("EDIT INITIAL TRANSFORM");
        const {x, y, scale} = coords || DEFAULT_BACKGROUND_DATA;
        editTransform(x, y, scale);
        rerenderBg();
      }

      return output;
    } else {
      return {};
    }
  };

  useEffect(() => {
    let isCancelled = false; // Flag to track unresolved requests

    const getBgs = async () => {
      try {
        setIsLoadingBackgrounds(true);

        // First, load the priority background (default or saved)
        const priorityIndex = hash ? parseInt(bgOption) : 1;
        const bgs = await API.getBackgrounds(channel.value, type.value, {
          progressive: true,
          priorityIndex,
          onUpdate: async (updatedBgs) => {
            if (!isCancelled) {
              const transforms = await getBgTransforms(updatedBgs);
              editAllBgUrls(updatedBgs);
              editBgData(transforms);
              setBgAmount(updatedBgs.length);
            }
          },
        });

        if (!isCancelled) {
          const transforms = await getBgTransforms(bgs);

          editAllBgUrls(bgs);
          editBgData(transforms);
          setBgAmount(bgs.length);

          if (bgs.length < 1) {
            console.log("no backgrounds found");
          }
        }
      } catch (error) {
        if (!isCancelled) {
          console.error(error);
        }
      } finally {
        if (!isCancelled) {
          setIsLoadingBackgrounds(false);
        }
      }
    };

    getBgs();

    // Cancel the request when unmounting or dependencies update
    return () => {
      isCancelled = true;
    };
  }, [channel, type]);

  return (
    <aside>
      <div className="toolbar-inner">
        <span className="label">Channel</span>
        <Select
          className="select"
          options={channelList}
          value={channel}
          onChange={(value) => onChangeChannel(value)}
          size="m"
          placeholder="Select template"
        />

        <span className="label">Product</span>
        <Select
          className="select"
          options={productList}
          value={product}
          onChange={(value) => onChangeProduct(value)}
          size="m"
          isDisabled={productList?.length < 2}
          placeholder="Select product"
          isSearchable={true}
        />

        {channel.value === "livestream" && (
          <div className={cn("toolbar-row", textCn("rs-text-2"))}>
            <a
              className={textCn("rs-link", {external: true, mode: "standalone"})}
              href={PREPARE_PHOTO_LINK}
              target={"_blank"}
            >
              {"Prepare Photo"}
            </a>
          </div>
        )}

        {typeList?.length > 1 && (
          <>
            <span className="label">Content type</span>
            <Select
              className="select"
              options={typeList}
              value={type}
              onChange={(value) => onChangeType(value)}
              size="m"
              placeholder="Select type"
            />
          </>
        )}

        {(channel.value === "social" || channel.value === "email") && (
          <div className="localization-wrap">
            <div className="localization">
              <span className="label">Localization</span>
              <Toggle
                checked={isFontToggle}
                className="font-toggle"
                onChange={(e) => {
                  setIsFontToggle(e.target.checked);
                }}
              ></Toggle>
            </div>

            {isFontToggle && <FontSelector />}
          </div>
        )}

        {!["livestream", "print"].includes(channel.value) &&
          !newsletterLayoutTypes.includes(type.value) && (
            <>
              <span className="label">Layout</span>
              <LayoutSelector amount={layoutAmount} />
            </>
          )}

        {+layoutOption === 7 && (
          <>
            <span className="label">Icon</span>
            <IconSelector amount={iconAmount} />
          </>
        )}

        <span className="label">Background {isLoadingBackgrounds ? "(Loading...)" : ""}</span>
        <BgSelector amount={bgAmount} />

        {(isToggleOnSocial || isToggleOnInternal || channel.value === "livestream") && (
          <div className="localization-wrap">
            <div className="localization">
              <span className="label">{`Dropzone ${isDropzoneVisible ? "visible" : "hidden"}`}</span>
              <Toggle
                checked={isDropzoneVisible}
                className="font-toggle"
                onChange={(e) => {
                  makeDropzoneVisible(e.target.checked);
                }}
              ></Toggle>
            </div>
          </div>
        )}

        <div className="toolbar-btns">
          <Button
            className="btn"
            onClick={saveSceneTrigger}
            size="m"
            mode="outline"
            id={"save_button"}
          >
            <span>{copyButtonText}</span>
          </Button>

          <Button
            className="btn"
            onClick={handleExport}
            size="m"
            id={"export_button"}
            mode="outline"
          >
            {isExportLoading ? <div className="loader"></div> : <span>Export</span>}
          </Button>
        </div>
        {/*{channel.value === "print" && (
          <div className="toolbar-btns">
            <Button className="btn" onClick={handlePDF} size="m" id={"pdf_button"} mode="outline">
              {isExportLoading ? <div className="loader"></div> : <span>Export PDF</span>}
            </Button>
          </div>
        )}*/}
      </div>
      <footer>
        <div className={cn("footer-link-wrapper", textCn("rs-text-2"))}>
          <InfoIcon color={RS_COLOR_GREY_40} />
          <a
            className={cn("footer-link", textCn("rs-link", {mode: "clear"}))}
            href={MANUAL_LINK}
            target={"_blank"}
          >
            {"Read the manual"}
          </a>
        </div>
        <div className={cn("footer-link-wrapper", textCn("rs-text-2"))}>
          <MessageIcon color={RS_COLOR_GREY_40} />
          <a
            className={cn("footer-link", textCn("rs-link", {mode: "clear"}))}
            href={FEEDBACK_LINK}
            target={"_blank"}
          >
            {"Leave your feedback"}
          </a>
        </div>
      </footer>
    </aside>
  );
};

export default Toolbar;
