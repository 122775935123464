import React, {useState, useEffect, useRef} from "react";
import "./Textarea.css";
import cn from "classnames";
import {findParentWithClass} from "../../libs/tools";
import {useSetting} from "../../contexts/setting";
import {useEdits} from "../../contexts/edits";
import {TEXT_CLASSNAMES} from "../../data/constants";

const Textarea = ({text = "", id, inline = false}) => {
  const [type, setType] = useState("");
  const [wasLocked, setWasLocked] = useState(false);
  const [lockedText, setLockedText] = useState(text);
  const {fontOption, locked} = useSetting();
  const {
    editSubtitles,
    editPositions,
    editTitles,
    editNames,
    editBadges,
    editDates,
    editEaps,
    editLinks,
    cleanupLocalChanges,
    titles,
    subtitles,
    names,
    badges,
    positions,
    dates,
    eaps,
    links,
  } = useEdits();
  const labelRef = useRef(null);

  const isLocked = locked.some((elem) => elem === id);

  const getCurrentText = () => {
    if (!isLocked) {
      return text;
    }

    // If locked, use local text if it exists
    let localText;
    switch (type) {
      case "title":
        localText = titles[id];
        break;
      case "subtitle":
        localText = subtitles[id];
        break;
      case "name":
        localText = names[id];
        break;
      case "badge":
        localText = badges[id];
        break;
      case "date":
        localText = dates[id];
        break;
      case "position":
        localText = positions[id];
        break;
      case "eap":
        localText = eaps[id];
        break;
      case "link":
        localText = links[id];
        break;
      default:
        localText = undefined;
    }

    // If we have local text for this locked banner, use it
    // Otherwise, use the text that was there when it was locked
    return localText !== undefined ? localText : lockedText;
  };

  const onTextChange = (e) => {
    console.log(type);
    switch (type) {
      case "title":
        editTitles(e.target.value, id, isLocked);
        break;
      case "subtitle":
        editSubtitles(e.target.value, id, isLocked);
        break;
      case "name":
        editNames(e.target.value, id, isLocked);
        break;
      case "badge":
        editBadges(e.target.value, id, isLocked);
        break;
      case "date":
        editDates(e.target.value, id, isLocked);
        break;
      case "position":
        editPositions(e.target.value, id, isLocked);
        break;
      case "link":
        editLinks(e.target.value, id, isLocked);
        break;
      case "eap":
        editEaps(e.target.value, id, isLocked);
        break;

      default:
        break;
    }
    labelRef.current.dataset.value = e.target.value;
  };

  const textareaClass = cn("textarea", `font-${fontOption}`);

  useEffect(() => {
    const t = findParentWithClass(labelRef.current, TEXT_CLASSNAMES);
    setType(t);
  }, []);

  useEffect(() => {
    const currentText = getCurrentText();
    labelRef.current.dataset.value = currentText;
  }, [
    text,
    type,
    isLocked,
    titles,
    subtitles,
    names,
    badges,
    links,
    positions,
    dates,
    eaps,
    lockedText,
  ]);

  useEffect(() => {
    if (!wasLocked && isLocked) {
      // When banner becomes locked, store current text
      setLockedText(text);
    } else if (wasLocked && !isLocked) {
      // When banner becomes unlocked, cleanup local changes
      cleanupLocalChanges(id);
    }
    setWasLocked(isLocked);
  }, [isLocked, wasLocked, id, cleanupLocalChanges, text]);

  return (
    <div className="textarea-wrap">
      <label className="input-sizer" ref={labelRef}>
        {inline ? (
          <input
            type="text"
            size="1"
            className={textareaClass}
            onChange={onTextChange}
            value={getCurrentText()}
            data-gramm="false"
            data-gramm_editor="false"
            data-enable-grammarly="false"
            spellCheck="false"
          />
        ) : (
          <textarea
            className={textareaClass}
            onChange={onTextChange}
            value={getCurrentText()}
            rows="1"
            data-gramm="false"
            data-gramm_editor="false"
            data-enable-grammarly="false"
            spellCheck="false"
          />
        )}
      </label>
    </div>
  );
};

export default Textarea;
