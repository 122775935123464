import React from "react";
import "./index.css";
import Textarea from "../../../layout/Textarea/Textarea";
import {logoNorm, logoIDE, logoAcademyBlack, UI} from "../../../data/constants";
import {checkIfIDE, getLogoUrl} from "../../../libs/tools";
import BgTransformer from "../../../layout/BgTransformer";
import {useSetting} from "../../../contexts/setting";
import {useEdits} from "../../../contexts/edits";
import cn from "classnames";
import Icon from "../../../layout/Icon";
import ImageUpload from "../../../layout/ImageUpload";
import {useImages} from "../../../contexts/images";

const InstagramStoriesLivestream = ({ id, zoom, }) => {
  const { product, layoutOption, iconOption } = useSetting();
  const { titles, badges, dates } = useEdits();
  const { bgUrl } = useImages();
  const logoURL = getLogoUrl(product.value, bgUrl);

  const title = titles[id] || titles.main;
  const badge = badges[id] || badges.main;
  const date = dates[id] || dates.main;
  const templateClass = cn("template", `layout-${layoutOption}`);
  const logoClass = cn("logo", {ide: checkIfIDE(product.value)});

  return (
    <>
      <div id={id} className={templateClass} style={{"--zoom-level": zoom}}>
        <div>
          <BgTransformer src={bgUrl} id={id} />
          <div className="content">
            <div className="title-wrap">
              <header className="template-header">
                <div className={logoClass}>
                  <img src={logoURL} alt="background" />
                </div>
                {(["JB", "EDU", "MP", "GDV",  "DN"].includes(product.value)) ? "" : (
                  <div className="product-name">
                    {product.label}
                  </div>
                )}
              </header>
              <div className="badge">
                <Textarea id={id} text={badge} inline={true} />
              </div>
              <h1 className="title">
                <Textarea id={id} text={title} />
              </h1>
            </div>
            <div className="date">
              <Textarea id={id} text={date} />
            </div>
            <div className="upload-wrap">
              <ImageUpload id={id} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InstagramStoriesLivestream;
