import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import {useMutation} from "@tanstack/react-query";
import cn from "classnames";
import {ThemeProvider} from "@rescui/ui-contexts";

import "@rescui/colors/lib/index.css";

import Toolbar from "./layout/Toolbar";
import "./App.css";
import "./css/fonts.css";
import {API} from "./api.js";
import {findChannelObj, PRODUCTS} from "./data/products.js";
import {exportLayouts, findBgUrl} from "./libs/tools";
import {useEdits} from "./contexts/edits.js";
import {useImages} from "./contexts/images";
import {useSetting} from "./contexts/setting";
import {templates} from "./data/templates";
import {TAGS} from "./data/constants";
import {useChannelFromParams} from "hooks/useChannelFromParams";
import {useProductFromParams} from "hooks/useProductFromParams";
import {usePalettes} from "hooks/usePalettes";
import {useScene} from "hooks/useScene";
import {useCopyUrl} from "hooks/useCopyUrl";
import {SceneNotFound} from "pages/SceneNotFound/SceneNotFound";

const App = () => {
  const {dates, names, positions, titles, subtitles, productNames, fontVariation, badges} =
    useEdits();
  const {
    bgX,
    bgY,
    bgScale,
    uploadX,
    uploadY,
    uploadScale,
    customBg,
    editBgUrl,
    images,
    allBgUrls,
    editBgUrlMini,
  } = useImages();
  const {
    bgOption,
    product,
    channel,
    type,
    layoutOption,
    addVisible,
    editTag,
    visible,
    locked,
    editLayoutAmount,
    editIsExportLoading,
    tag,
  } = useSetting();
  const location = useLocation();
  const navigate = useNavigate();
  const channelFromParams = useChannelFromParams();
  const productFromParams = useProductFromParams();

  useEffect(() => {
    if (location.hash) {
      const hashId = location.hash.substring(1);
      navigate(`${location.pathname}/${hashId}`, {replace: true});
    }
  }, [location, navigate]);

  const theme = "dark";
  const [classNames, setClassNames] = useState("");

  // TODO refactor
  const loadNormBg = () => {
    if (allBgUrls.length > 0) {
      const src = findBgUrl(allBgUrls, +bgOption, `/${product.value}`);
      // const prodHash = getProductHash(src);
      // const src2x = findBgUrl(allBgUrls, +bgOption, `/${product.value}@2x`);
      const srcMini = findBgUrl(allBgUrls, +bgOption, `/${product.value}-mini`);
      editBgUrl(src);
      // editProductHash(prodHash);
      // editBgUrl2x(src2x);
      editBgUrlMini(srcMini);
      editTag(TAGS[2]);

      let newClassNames = "";
      if (src) {
        if (src.includes("_blacklogo")) {
          newClassNames += " blacklogo";
        }
        if (src.includes("_blacktext")) {
          newClassNames += " blacktext";
        }
        if (src.includes("_productlogo")) {
          newClassNames += " productlogo";
        }
        if (src.includes("_whitebg")) {
          newClassNames += " whitebg";
        }
        if (src.includes("_whitelogo")) {
          newClassNames += " whitelogo";
        }
        if (src.includes("_graybg")) {
          newClassNames += " graybg";
        }
        if (src.includes("_blackproduct")) {
          newClassNames += " blackproduct";
        }
        if (src.includes("_bottomlogoinverse")) {
          newClassNames += " bottomlogoinverse";
        }
      }
      newClassNames += ` ${product.value}`;
      // Add class for active background
      if (bgOption !== "custom") {
        newClassNames += ` bg-${bgOption}`;
      }
      setClassNames(newClassNames);

      if (!src) {
        const outdated = findBgUrl(allBgUrls, +bgOption, `/${product.value}-outdated`);
        // const outdated2x = findBgUrl(allBgUrls, +bgOption, `/${product.value}@2x-outdated`);
        const outdatedMini = findBgUrl(allBgUrls, +bgOption, `/${product.value}-mini-outdated`);
        editBgUrl(outdated);
        // editBgUrl2x(outdated2x);
        editBgUrlMini(outdatedMini);

        const isOutdated = PRODUCTS.find((elem) => elem.value === product.value).tag === "outdated";
        if (isOutdated) {
          editTag(TAGS[1]);
        } else {
          editTag(TAGS[2]);
        }

        if (!outdated) {
          editTag(TAGS[0]);
        }
      }
    }
  };

  useEffect(() => {
    // console.log("bgOption",bgOption)
    if (bgOption === "custom") {
      editBgUrl(customBg);
    } else {
      loadNormBg();
    }
  }, [product, bgOption, customBg, allBgUrls]);

  const {id: templateId} = useParams();

  const {isLoaded: isSceneLoaded, isError, error} = useScene();

  usePalettes();

  useEffect(() => {
    templates[channel.value].forEach((t) => {
      addVisible(t.id);
    });
    const chObj = findChannelObj(channel.value);
    const isIconSelector = product.value === "IJ" && channel.value === "social";
    const layoutsTotalNumber = isIconSelector ? +chObj.layoutsNumber + 1 : chObj.layoutsNumber;
    editLayoutAmount(layoutsTotalNumber);
  }, [channel, product]);

  const exportImages = async () => {
    editIsExportLoading(true);
    const wrappers = document.querySelectorAll(".template-wrap");
    const visible = [...wrappers].filter((w) => !w.classList.contains("hidden"));
    [...visible].forEach((el) => el.classList.add("nobg"));
    const nodes = visible.flatMap((element) => Array.from(element.querySelectorAll(".template")));
    // const nodes = document.querySelectorAll(".template");

    // Get the main title to use in the filename
    const mainTitle = titles.main || "";

    await exportLayouts([...nodes], product.value, channel.value, mainTitle);
    [...visible].forEach((el) => el.classList.remove("nobg"));
    editIsExportLoading(false);
  };

  const { copyButtonText, copyUrlToClipboard } = useCopyUrl();

  const {mutate, isSuccess, isPending, data} = useMutation({
    mutationKey: ["save-scene"],
    mutationFn: (params) => API.saveScene(params),
  });

  const saveScene = useCallback(() => {
    const scene = {
      product,
      channel,
      type,
      visible,
      locked,
      titles,
      dates,
      positions,
      names,
      productNames,
      fontVariation,
      bgX,
      bgY,
      bgScale,
      uploadX,
      uploadY,
      uploadScale,
      bgOption,
      customBg,
      layoutOption,
      images,
      subtitles,
      badges,
    };

    mutate(scene);
  }, [
    mutate,
    product,
    channel,
    type,
    visible,
    locked,
    titles,
    dates,
    positions,
    names,
    productNames,
    fontVariation,
    bgX,
    bgY,
    bgScale,
    uploadX,
    uploadY,
    uploadScale,
    bgOption,
    customBg,
    layoutOption,
    images,
    subtitles,
    badges,
  ]);

  useEffect(() => {
    if (isSuccess && !isPending) {
      const {scene_id} = data;

      navigate(`${channelFromParams.value}/${productFromParams.value}/${scene_id}`, {});
      // Copy URL to clipboard after navigation
      setTimeout(copyUrlToClipboard, 100);
    }
  }, [data, isSuccess, isPending, copyUrlToClipboard]);

  const tagClass = cn("tag", `tag-${tag?.value}`);

  const renderContent = () => {
    if (isError && error?.status === 404) return <SceneNotFound />;
    if (templateId && !isSceneLoaded) return <div>{"Loading..."}</div>;
    return <Outlet />;
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={`App${classNames}`}>
        <main>
          <div className="templates">
            <div className="templates-inner">
              <label className={tagClass}>{tag?.label}</label>
              {renderContent()}
            </div>
          </div>
          <Toolbar handleExport={exportImages} handleSaveScene={saveScene} copyButtonText={copyButtonText} />
        </main>
      </div>
    </ThemeProvider>
  );
};

export default App;
