import {cloneNode} from "./clone-node";
import {embedImages} from "./embed-images";
import {applyStyle} from "./apply-style";
import {embedWebFonts, getWebFontCSS} from "./embed-webfonts";
import {
  getImageSize,
  getPixelRatio,
  createImage,
  canvasToBlob,
  nodeToDataURL,
  checkCanvasDimensions,
} from "./util";

export async function toCanvas(node, options) {
  const {width, height} = getImageSize(node, options);
  const svg = await toSvg(node, options);
  const img = await createImage(svg);

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d", {alpha: true});
  if (!context) {
    throw new Error("Canvas context 2d is not supported");
  }

  // Enable image smoothing for better quality in Safari
  context.imageSmoothingEnabled = true;
  context.imageSmoothingQuality = "high";

  const ratio = options?.pixelRatio || getPixelRatio();
  const canvasWidth = options?.canvasWidth || width;
  const canvasHeight = options?.canvasHeight || height;

  canvas.width = canvasWidth * ratio;
  canvas.height = canvasHeight * ratio;

  if (!options?.skipAutoScale) {
    checkCanvasDimensions(canvas);
  }
  canvas.style.width = `${canvasWidth}`;
  canvas.style.height = `${canvasHeight}`;

  if (options?.backgroundColor) {
    context.fillStyle = options?.backgroundColor;
    context.fillRect(0, 0, canvas.width, canvas.height);
  }

  try {
    context.drawImage(img, 0, 0, canvas.width, canvas.height);
  } catch (error) {
    console.error("[DEBUG_LOG] Canvas drawImage error:", error);
    throw new Error("Failed to draw image on canvas: " + error.message);
  }

  return canvas;
}

export async function toSvg(node, options) {
  const {width, height} = getImageSize(node, options);
  const clonedNode = await cloneNode(node, options, true);
  await embedWebFonts(clonedNode, options);
  await embedImages(clonedNode, options);
  applyStyle(clonedNode, options);
  const datauri = await nodeToDataURL(clonedNode, width, height);
  return datauri;
}

export async function toPng(node, options) {
  try {
    const canvas = await toCanvas(node, options);
    return canvas.toDataURL("image/png", 1.0);
  } catch (error) {
    console.error("[DEBUG_LOG] PNG conversion error:", error);
    throw error;
  }
}

/*function px(node, styleProperty) {
  const win = node.ownerDocument.defaultView || window
  const val = win.getComputedStyle(node).getPropertyValue(styleProperty)
  return val ? parseFloat(val.replace('px', '')) : 0
}

function getImageSize(targetNode, options) {
  const width = options.width || getNodeWidth(targetNode)
  const height = options.height || getNodeHeight(targetNode)

  return { width, height }
}

function getNodeWidth(node) {
  const leftBorder = px(node, 'border-left-width')
  const rightBorder = px(node, 'border-right-width')
  return node.clientWidth + leftBorder + rightBorder
}

function getNodeHeight(node) {
  const topBorder = px(node, 'border-top-width')
  const bottomBorder = px(node, 'border-bottom-width')
  return node.clientHeight + topBorder + bottomBorder
}

function createImage(url) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.decode = () => resolve(img)
    img.onload = () => resolve(img)
    img.onerror = reject
    img.crossOrigin = 'anonymous'
    img.decoding = 'async'
    img.src = url
  })
}

function getPixelRatio() {
  let ratio

  let FINAL_PROCESS
  try {
    FINAL_PROCESS = process
  } catch (e) {
    // pass
  }

  const val =
    FINAL_PROCESS && FINAL_PROCESS.env
      ? FINAL_PROCESS.env.devicePixelRatio
      : null
  if (val) {
    ratio = parseInt(val, 10)
    if (Number.isNaN(ratio)) {
      ratio = 1
    }
  }
  return ratio || window.devicePixelRatio || 1
}

async function cloneNode(node, options, isRoot) {
  if (!isRoot && options.filter && !options.filter(node)) {
  return null
}

return Promise.resolve(node)
  .then((clonedNode) => cloneSingleNode(clonedNode, options))
  .then((clonedNode) => cloneChildren(node, clonedNode, options))
  .then((clonedNode) => decorate(node, clonedNode))
  .then((clonedNode) => ensureSVGSymbols(clonedNode, options))
}*/
