import React, {useContext, useState, createContext, useCallback} from "react";

export const Edits = createContext({
  names: {},
  titles: {},
  subtitles: {},
  badges: {},
  positions: {},
  dates: {},
  fontVariation: {},
  productNames: {},
  editProductNames: {},
  images: {},
  text1: {},
  text2: {},
  bgTransform: {},
  editNames: () => {},
  editEaps: () => {},
  editTitles: () => {},
  editDates: () => {},
  editPositions: () => {},
  editSubtitles: () => {},
  editBadges: () => {},
  reset: () => {},
  editFontVariation: () => {},
  editImages: () => {},
  cleanupLocalChanges: () => {},
});

export const EditsContextProvider = ({children}) => {
  const [names, setNames] = useState({});
  const [titles, setTitles] = useState({});
  const [subtitles, setSubtitles] = useState({});
  const [positions, setPositions] = useState({});
  const [dates, setDates] = useState({});
  const [badges, setBadges] = useState({});
  const [productNames, setProductNames] = useState({});
  const [fontVariation, setFontVariation] = useState(1);
  const [images, setImages] = useState({});
  const [eaps, setEaps] = useState({});
  const [links, setLinks] = useState({});

  const reset = () => {
    setNames({});
    setTitles({});
    setDates({});
    setPositions({});
    setProductNames({});
    setFontVariation(1);
    setImages({});
    setEaps({});
    setBadges({});
    setSubtitles({});
    setLinks({});
  };

  const editLinks = (value, id, isLocked = false) => {
    const currentId = isLocked ? id : "main";

    setLinks((curLinks) => {
      const nextLinks = {...curLinks};
      if (!nextLinks[currentId]) {
        nextLinks[currentId] = {};
      }
      nextLinks[currentId] = value;
      return nextLinks;
    });
  };

  const editSubtitles = useCallback((value, id, isLocked = false) => {
    const currentId = isLocked ? id : "main";

    setSubtitles((curTitles) => {
      const nextTitles = {...curTitles};
      if (!nextTitles[currentId]) {
        nextTitles[currentId] = {};
      }
      nextTitles[currentId] = value;
      return nextTitles;
    });
  }, []);

  const editBadges = (value, id, isLocked = false) => {
    const currentId = isLocked ? id : "main";

    setBadges((curBadges) => {
      const nextBadges = {...curBadges};
      if (!nextBadges[currentId]) {
        nextBadges[currentId] = {};
      }
      nextBadges[currentId] = value;
      return nextBadges;
    });
  };
  const editNames = (value, id, isLocked = false) => {
    const currentId = isLocked ? id : "main";

    setNames((curNames) => {
      const nextNames = {...curNames};
      if (!nextNames[currentId]) {
        nextNames[currentId] = {};
      }
      nextNames[currentId] = value;
      return nextNames;
    });
  };
  const editImages = (value) => {
    setImages(value);
  };

  const editEaps = (value, id, isLocked = false) => {
    const currentId = isLocked ? id : "main";

    setEaps((curEaps) => {
      const nextEaps = {...curEaps};
      if (!nextEaps[currentId]) {
        nextEaps[currentId] = {};
      }
      nextEaps[currentId] = value;
      return nextEaps;
    });
  };

  const editTitles = (value, id, isLocked = false) => {
    const currentId = isLocked ? id : "main";

    setTitles((curTitles) => {
      const nextTitles = {...curTitles};
      if (!nextTitles[currentId]) {
        nextTitles[currentId] = {};
      }
      nextTitles[currentId] = value;
      return nextTitles;
    });
  };

  const loadTitles = useCallback((value) => {
    setTitles(value);
  }, []);

  const loadSubtitles = useCallback((value) => {
    setSubtitles(value);
  }, []);

  const loadNames = useCallback((value) => {
    setNames(value);
  }, []);

  const loadBadges = useCallback((value) => {
    setBadges(value);
  }, []);

  const loadDates = useCallback((value) => {
    setDates(value);
  }, []);

  const loadPositions = useCallback((value) => {
    setPositions(value);
  }, []);

  const editDates = (value, id, isLocked = false) => {
    const currentId = isLocked ? id : "main";

    // Ensure value is converted to string if it's a Date object
    const formattedValue = value instanceof Date ? value.toLocaleDateString() : value;

    setDates((curDates) => {
      const nextDates = {...curDates};
      if (!nextDates[currentId]) {
        nextDates[currentId] = {};
      }
      nextDates[currentId] = formattedValue;
      return nextDates;
    });
  };

  const editPositions = (value, id, isLocked = false) => {
    const currentId = isLocked ? id : "main";

    setPositions((curPositions) => {
      const nextPositions = {...curPositions};
      if (!nextPositions[currentId]) {
        nextPositions[currentId] = {};
      }
      nextPositions[currentId] = value;
      return nextPositions;
    });
  };

  const editProductNames = (value) => {
    setProductNames(value);
  };

  const editFontVariation = useCallback((value) => {
    setFontVariation(value);
  }, []);

  const cleanupLocalChanges = (id) => {
    // Remove local changes for the specified banner ID from all text fields
    setNames((prev) => {
      const next = {...prev};
      delete next[id];
      return next;
    });
    setTitles((prev) => {
      const next = {...prev};
      delete next[id];
      return next;
    });
    setSubtitles((prev) => {
      const next = {...prev};
      delete next[id];
      return next;
    });
    setBadges((prev) => {
      const next = {...prev};
      delete next[id];
      return next;
    });
    setPositions((prev) => {
      const next = {...prev};
      delete next[id];
      return next;
    });
    setDates((prev) => {
      const next = {...prev};
      delete next[id];
      return next;
    });
    setEaps((prev) => {
      const next = {...prev};
      delete next[id];
      return next;
    });
  };

  return (
    <Edits.Provider
      value={{
        names,
        titles,
        positions,
        dates,
        productNames,
        fontVariation,
        images,
        eaps,
        subtitles,
        badges,
        links,
        editEaps,
        loadTitles,
        loadSubtitles,
        loadPositions,
        loadNames,
        loadBadges,
        loadDates,
        editProductNames,
        editNames,
        editLinks,
        editTitles,
        editDates,
        editPositions,
        editFontVariation,
        editImages,
        reset,
        editSubtitles,
        editBadges,
        cleanupLocalChanges,
      }}
    >
      {children}
    </Edits.Provider>
  );
};

export function useEdits() {
  const context = useContext(Edits);
  if (context === undefined) {
    throw new Error("Edits.Context must be used within the Edits.Provider");
  }
  return context;
}
