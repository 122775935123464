import React, {useEffect, useRef} from "react";
import cn from "classnames";
import {useTextStyles} from "@rescui/typography";

import "./SceneNotFound.css";
import img from "./goose_spaceman_small.png";

const STARS_COUNT = 300;
const BACKGROUND_COLOR = "#000000";
const ROTATION_ANGLE_STEP = 0.02;

export const SceneNotFound = () => {
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const starsRef = useRef([]);
  const textCn = useTextStyles();

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const image = new Image();
    image.src = img;

    let imageX = Math.random() * 200;
    let imageY = Math.random() * 200;
    let velocityX = 0.5 + Math.random() * 1;
    let velocityY = 0.5 + Math.random() * 1;
    let rotationAngle = 0;

    const randomizeGooseEntry = (width, height) => {
      const side = Math.floor(Math.random() * 4);

      velocityX = (0.5 + Math.random() * 1) * (Math.random() > 0.5 ? 1 : -1);
      velocityY = (0.5 + Math.random() * 1) * (Math.random() > 0.5 ? 1 : -1);

      switch (side) {
        case 0:
          imageX = Math.random() * width;
          imageY = -100;
          if (velocityY < 0) velocityY = -velocityY;
          break;

        case 1:
          imageX = Math.random() * width;
          imageY = height + 100;
          if (velocityY > 0) velocityY = -velocityY;
          break;

        case 2:
          imageX = -100;
          imageY = Math.random() * height;
          if (velocityX < 0) velocityX = -velocityX;
          break;

        case 3:
          imageX = width + 100;
          imageY = Math.random() * height;
          if (velocityX > 0) velocityX = -velocityX;
          break;

        default:
          break;
      }

      imageX = Math.min(Math.max(imageX, -100), width + 100);
      imageY = Math.min(Math.max(imageY, -100), height + 100);
    };

    const resizeCanvas = () => {
      const container = containerRef.current;

      const width = container.clientWidth;
      const height = container.clientHeight;

      canvas.style.width = `${width}px`;
      canvas.style.height = `${height}px`;

      const devicePixelRatio = window.devicePixelRatio || 1;
      canvas.width = width * devicePixelRatio;
      canvas.height = height * devicePixelRatio;
      ctx.scale(devicePixelRatio, devicePixelRatio);

      if (starsRef.current.length === 0) {
        generateStars(width, height);
      }

      drawStaticStars();
    };

    const generateStars = (width, height) => {
      starsRef.current = [];
      for (let i = 0; i < STARS_COUNT; i++) {
        starsRef.current.push({
          x: Math.random() * width,
          y: Math.random() * height,
          radius: Math.random() * 2,
          opacity: Math.random(),
        });
      }
    };

    const drawStaticStars = () => {
      ctx.fillStyle = BACKGROUND_COLOR;
      ctx.fillRect(
        0,
        0,
        canvas.width / (window.devicePixelRatio || 1),
        canvas.height / (window.devicePixelRatio || 1)
      );

      starsRef.current.forEach((star) => {
        ctx.beginPath();
        ctx.arc(star.x, star.y, star.radius, 0, Math.PI * 2);
        ctx.fillStyle = `rgba(255, 255, 255, ${star.opacity})`;
        ctx.fill();
      });
    };

    const animate = () => {
      const width = canvas.width / (window.devicePixelRatio || 1);
      const height = canvas.height / (window.devicePixelRatio || 1);

      drawStaticStars();

      const imageWidth = 70;
      const imageHeight = (image.height / image.width) * imageWidth;

      rotationAngle += ROTATION_ANGLE_STEP;

      ctx.save();

      ctx.translate(imageX + imageWidth / 2, imageY + imageHeight / 2);
      ctx.rotate(rotationAngle);
      ctx.translate(-imageWidth / 2, -imageHeight / 2);

      ctx.drawImage(image, 0, 0, imageWidth, imageHeight);

      ctx.restore();

      imageX += velocityX;
      imageY += velocityY;

      if (imageX > width + 100 || imageX < -100 || imageY > height + 100 || imageY < -100) {
        randomizeGooseEntry(width, height);
      }

      requestAnimationFrame(animate);
    };

    resizeCanvas();
    image.onload = () => {
      animate();
    };

    window.addEventListener("resize", resizeCanvas);

    return () => window.removeEventListener("resize", resizeCanvas);
  }, []);

  return (
    <div className="scene-container" ref={containerRef}>
      <canvas ref={canvasRef} className="scene-canvas" />
      <div className="scene-message">
        <h1 className={textCn("rs-h1")}>Scene not found</h1>
        <a className={cn(textCn("rs-link", {mode: "clear"}))} href="/">
          {"Back to home page"}
        </a>
      </div>
    </div>
  );
};
