import {useEffect} from "react";
import {useQuery} from "@tanstack/react-query";

import {API} from "api";
import {useSetting} from "contexts/setting";

export function usePalettes() {
  const {editPalettes} = useSetting();

  const {data, isSuccess} = useQuery({
    queryKey: ["fetch-palettes"],
    queryFn: API.fetchPalettes,
    select(data) {
      const palettes = {};
      const krakenProps = data.all;

      for (const product in krakenProps) {
        const colors = krakenProps[product].palette;
        palettes[product] = colors;
      }

      return palettes;
    },
  });

  useEffect(() => {
    if (isSuccess) {
      editPalettes(data);
    }
  }, [isSuccess, data, editPalettes]);
}
