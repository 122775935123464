import React, {useState} from "react";
import "./index.css";
import cn from "classnames";
import Textarea from "../../../layout/Textarea/Textarea";
import ImageUpload from "../../../layout/ImageUpload/index";
import {logoNorm, logoIDE, UI} from "../../../data/constants";
import {checkIfIDE} from "../../../libs/tools";
import Icon from "../../../layout/Icon";
import BgTransformer from "../../../layout/BgTransformer";
import {useSetting} from "../../../contexts/setting";
import {useEdits} from "../../../contexts/edits";
import {useImages} from "../../../contexts/images";

const Email01 = ({id, zoom}) => {
  const {product, layoutOption, iconOption} = useSetting();
  const {titles, eaps, badges} = useEdits();
  const [isInline, setIsInline] = useState(true);

  const {bgUrl} = useImages();
  const title = titles[id] || titles.main;
  const eap = eaps[id] || eaps.main;
  const templateClass = cn("template", `layout-${layoutOption}`);
  const logoClass = cn("logo", {ide: checkIfIDE(product.value)});

  return (
    <>
      <div id={id} className={templateClass} style={{"--zoom-level": zoom}}>
        <div>
          <BgTransformer src={bgUrl} id={id} />

          <div className="content">
            <div className="text-wrap">
              {+layoutOption === 1 && (
                <h1 className="title">
                  <Textarea id={id} text={title} />
                </h1>
              )}
              {+layoutOption === 2 && (
                <div className="text-group">
                  {product.value === "JB" ? (
                    ""
                  ) : (
                    <div className="product-name">{product.label}</div>
                  )}
                  <div className="eap">
                    <Textarea id={id} text={eap} inline={isInline} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Email01;
