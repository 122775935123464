import React, {useEffect, useState} from "react";
import {useImages} from "../../contexts/images";
import {useSetting} from "../../contexts/setting";
import cn from "classnames";
import "./index.css";
import {DEFAULT_BACKGROUND_DATA} from "../../data/backgrounds";
import {findBgUrl, getResizedImageBase64, getThumbnailUrl} from "../../libs/tools";

const Button = ({value, handleClick, src, isCurrent, isLoading}) => {
  const btnClass = cn("bg-button", {
    selected: isCurrent,
    loading: isLoading,
  });
  return (
    <button
      value={value}
      className={btnClass}
      onClick={() => handleClick(value)}
      style={{
        backgroundImage: src ? `url(${src})` : "none",
      }}
    >
      {isLoading && <div className="bg-loading-indicator">Loading...</div>}
    </button>
  );
};

const UploadButton = ({handleClick, isCurrent}) => {
  const btnClass = cn("bg-button upload-button");
  return (
    <div>
      <input
        type="file"
        accept="image/*"
        onChange={(e) => handleClick(e, "custom")}
        style={{display: "none"}}
        id="upload-button"
      />
      <label htmlFor="upload-button" className={btnClass}>
        <span>Upload File</span>
      </label>
    </div>
  );
};

const BgSelector = ({amount}) => {
  const {bgOption, editBgOption, type, channel, product} = useSetting();
  const {editTransform, rerenderBg, editCustomBg, allBgUrls, bgData} = useImages();
  const [loadingStates, setLoadingStates] = useState({});
  const [currentBgUrls, setCurrentBgUrls] = useState({});

  const editSelectorUrls = (id, url) => {
    setCurrentBgUrls((curUrls) => {
      const nextUrls = {...curUrls};
      nextUrls[id] = url;
      return nextUrls;
    });
  };

  const getAllSrcs = () => {
    setCurrentBgUrls([]);
    if (allBgUrls && allBgUrls.length > 0) {
      allBgUrls.forEach((urlArray, i) => {
        // console.log(allBgUrls)
        const productImageUrl = findBgUrl(allBgUrls, i + 1, `/${product.value}`);
        // const productImageUrl = urlArray.find(url => url.includes(product.value));
        if (productImageUrl) {
          const newUrl = getThumbnailUrl(productImageUrl);
          editSelectorUrls(i, newUrl);
        } else {
          editSelectorUrls(i, "");
        }
      });
    } else {
      setCurrentBgUrls([]);
    }
  };

  useEffect(() => {
    getAllSrcs();
  }, [allBgUrls, product.value]);

  useEffect(() => {
    // Reset loading states when allBgUrls changes
    const newLoadingStates = {};
    if (allBgUrls) {
      console.log("all bg urls,", allBgUrls);
      allBgUrls.forEach((_, index) => {
        newLoadingStates[index + 1] = !currentBgUrls[index];
      });
    }
    setLoadingStates(newLoadingStates);
  }, [currentBgUrls, product.value]);

  useEffect(() => {
    transformBg();
  }, [product.value]);

  const transformBg = (option = bgOption) => {
    const coords = bgData[product.value]?.[option - 1] || bgData["default"]?.[option - 1];
    // console.log("coords: ", bgData[product.value]?.[option - 1]);
    // console.log("coords all: ", bgData[product.value]);
    // console.log(option);
    // console.log(bgData["default"]?.[option - 1]);
    // console.log(bgData[product.value]);
    const {x, y, scale} = coords || DEFAULT_BACKGROUND_DATA;
    // console.log("EDIT TRANSFORM");
    editTransform(x, y, scale);
    rerenderBg();
  };

  const onBgButtonClick = (value) => {
    editBgOption(value);
    transformBg(value);
    editCustomBg("");
  };
  const onBgUploadClick = (event, value) => {
    editBgOption(value);
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log("res", reader.result);
        editCustomBg(reader.result);
      };
      reader.readAsDataURL(file);
    }
    transformBg(value);
  };

  return (
    <div className="bg-selector">
      <UploadButton
        key="custom"
        value="custom"
        handleClick={onBgUploadClick}
        isCurrent={bgOption === "custom"}
      />

      {[...new Array(allBgUrls.length)].map((x, i) => {
        if (currentBgUrls[i]) {
          return (
            <Button
              key={i + 1}
              value={i + 1}
              src={currentBgUrls[i]}
              handleClick={onBgButtonClick}
              isCurrent={+bgOption === i + 1}
              isLoading={loadingStates[i + 1]}
            />
          );
        } else return "";
      })}
    </div>
  );
};

export default BgSelector;
