const UndoIcon = () => (
  <svg viewBox="0 0 24 24">
    <path
      fill="#fff"
      d="M17 13.6a4.12 4.12 0 00-4.18-3.6H11v5L4 9l7-6v5h1.79A6.15 6.15 0 0119 13.21 6 6 0 0113 20H7v-2h6a4 4 0 004-4.4z"
    ></path>
  </svg>
);

export default UndoIcon;
