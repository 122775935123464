import {useProductFromParams} from "hooks/useProductFromParams";
import React, {useContext, useState, createContext, useCallback} from "react";
import {useChannelFromParams} from "hooks/useChannelFromParams";
import {TAGS} from "data/constants";

export const Setting = createContext({
  setting: {},
  editSetting: () => {},
});

export const SettingContextProvider = ({children}) => {
  const channelFromParams = useChannelFromParams();
  const productFromParams = useProductFromParams();

  const [channel, setChannel] = useState(channelFromParams);
  const [product, setProduct] = useState(productFromParams);
  const [type, setType] = useState({label: "General", value: "general"});
  const [bgOption, setBgOption] = useState("1");
  const [layoutOption, setLayoutOption] = useState("1");
  const [iconOption, setIconOption] = useState("key");
  const [fontOption, setFontOption] = useState("en");
  const [palettes, setPalettes] = useState({});
  const [fontVariation, setFontVariations] = useState({});
  const [tag, setTag] = useState(TAGS[2]);
  const [locked, setLocked] = useState([]);
  const [visible, setVisible] = useState([]);
  const [hash, setHash] = useState("");
  const [isDropzoneVisible, setDropzoneVisible] = useState(true);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [isBgToolsExpanded, setIsBgToolsExpanded] = useState(false);
  const [isImageToolsExpanded, setIsImageToolsExpanded] = useState(false);
  const [layoutAmount, setLayoutAmount] = useState(7);

  const makeDropzoneVisible = useCallback((value) => {
    setDropzoneVisible((current) => {
      document.querySelectorAll(".upload-wrap").forEach((el) => {
        if (value !== true) {
          el.classList.add("collapsed");
        } else {
          el.classList.remove("collapsed");
        }
      });
      return !current;
    });
  }, []);

  const addVisible = useCallback((value) => {
    setVisible((curList) => [...curList, value]);
  }, []);

  const removeVisible = useCallback((value) => {
    setVisible((curList) => curList.filter((item) => item !== value));
  }, []);

  const editIsExportLoading = useCallback(setIsExportLoading, []);
  const editIsImageToolsExpanded = useCallback(setIsImageToolsExpanded, []);
  const editIsBgToolsExpanded = useCallback(setIsBgToolsExpanded, []);
  const editFontOption = useCallback(setFontOption, []);
  const editBgOption = useCallback(setBgOption, []);
  const editHash = useCallback(setHash, []);
  const editTag = useCallback(setTag, []);
  const editIconOption = useCallback(setIconOption, []);
  const editLayoutOption = useCallback(setLayoutOption, []);
  const editType = useCallback(setType, []);
  const editChannel = useCallback(setChannel, []);
  const editProduct = useCallback(setProduct, []);
  const editPalettes = useCallback(setPalettes, []);
  const editFontVariations = useCallback(setFontVariations, []);
  const editLayoutAmount = useCallback(setLayoutAmount, []);

  const editVisible = useCallback(
    (template, value) => {
      value ? addVisible(template) : removeVisible(template);
    },
    [addVisible, removeVisible]
  );

  const addLocked = useCallback((value) => {
    setLocked((curList) => [...curList, value]);
  }, []);

  const removeLocked = useCallback((value) => {
    setLocked((curList) => curList.filter((item) => item !== value));
  }, []);

  const editLocked = useCallback(
    (template, value) => {
      value ? addLocked(template) : removeLocked(template);
    },
    [addLocked, removeLocked]
  );

  const loadLocked = useCallback(setLocked, []);
  const loadVisible = useCallback(setVisible, []);

  return (
    <Setting.Provider
      value={{
        channel,
        product,
        type,
        palettes,
        fontVariation,
        bgOption,
        layoutOption,
        iconOption,
        tag,
        fontOption,
        visible,
        locked,
        hash,
        isDropzoneVisible,
        isExportLoading,
        isBgToolsExpanded,
        isImageToolsExpanded,
        editIsExportLoading,
        editLayoutAmount,
        makeDropzoneVisible,
        addVisible,
        removeVisible,
        addLocked,
        removeLocked,
        editFontOption,
        editChannel,
        editProduct,
        editType,
        editPalettes,
        editFontVariations,
        editBgOption,
        editLayoutOption,
        editIconOption,
        editTag,
        editLocked,
        loadLocked,
        loadVisible,
        editVisible,
        editHash,
        editIsImageToolsExpanded,
        editIsBgToolsExpanded,
        layoutAmount,
      }}
    >
      {children}
    </Setting.Provider>
  );
};

export function useSetting() {
  const context = useContext(Setting);
  if (context === undefined) {
    throw new Error("Setting.Context must be used within the Images.Provider");
  }
  return context;
}
